import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector }            from "react-redux";
import { Form }                                from "react-bootstrap";
import { useTranslation }                      from "react-i18next";

import {
    serializeFormToObject,
    submitFormByRef,
    upperCaseFirstLetter,
    viewAlert
}                        from "../../utils/misc";
import { getPrintPreset, printStickers }                    from "../../utils/api";

import Select                                               from "../input/Select";
import InputText                                            from "../input/InputText";
import Divider                                              from "../shared/Divider";
import ModalButtons                                         from "../shared/modal/ModalButtons";
import { setLoadSpinner, setModalForm }                     from "../../storage/global";

const PrintSettingsForm = () => {
    const maximum_quantity_stickers = process.env.MAX_STICKERS || 500;

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const MediaOptions = [
        {
            text: t('sheet'),
            value: 'sheet'
        },
        {
            text: t('tape'),
            value: 'tape'
        },
    ];

    const FormatOptions = [
        {
            text: t('custom'),
            value: 'custom'
        },
        {
            text: 'A3',
            value: 'A3'
        },
        {
            text: 'A4',
            value: 'A4'
        },
        {
            text: 'A5',
            value: 'A5'
        },
    ];

    const OrientationOptions = [
        {
            text: t('portrait'),
            value: 'portrait'
        },
        {
            text: t('landscape'),
            value: 'landscape'
        },
    ];

    const defaultPreset = {
        "media": t("sheet"),
        "format": t("custom"),
        "orientation": t("portrait"),
        "width": 104,
        "height": 104,
        "top": 2,
        "left": 2,
        "right": 2,
        "bottom": 2,
        "vertical-gap": 2,
        "horizontal-gap": 2
    }

    const VIEW = 1
    const DOWNLOAD = 2
    const FORMAT_HEIGHT = 'height';
    const FORMAT_TYPE = 'format';
    const FORMAT_WIDTH = 'width';
    const ORIENTATION_TYPE = 'orientation';
    const HORIZONTAL_GAP = 'horizontal-gap';
    const VERTICAL_GAP = 'vertical-gap';
    const MARGINS_BOTTOM = 'bottom';
    const MARGINS_LEFT = 'left';
    const MARGINS_RIGHT = 'right';
    const MARGINS_TOP = 'top';
    const MEDIA_TYPE = 'media';

    const [loaded, setLoaded] = useState(true)
    const [preset, setPreset] = useState(defaultPreset)
    const [rootPreset, setRootPreset] = useState(defaultPreset)

    const list = useSelector(state => state.global.list);

    const selectedList = useMemo(() => {
        return list.filter(parcel => parcel.check)
    }, [list])

    const formRef = React.createRef();
    const downloadRef = React.createRef();

    function hideModalForm() {
        dispatch(setModalForm({
            show: false
        }))
    }

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;

        const data = serializeFormToObject(form)

        //validate

        dispatch(setLoadSpinner(true))

        printStickers({
            ...data,
            uid: selectedList.map(parcel => parcel.uid)
        })
            .then(({data}) => {
                const type = Number(downloadRef.current.querySelector('input').value);
                const blob = new Blob([data], {type: 'application/pdf'});
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                if (type === VIEW)
                    link.target = '_blank';
                else
                    link.setAttribute('download', 'parcels.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
                hideModalForm()
            })
            .catch(({response}) => {
                viewAlert(dispatch, response)
            })
            .finally(() => dispatch(setLoadSpinner(false)))
    }

    useEffect(() => {
        return getPrintPreset()
            .then(({data}) => {
                setPreset(data[0].values)
                setRootPreset(data[0].values)
            })
            .catch()
            .finally(() => setLoaded(false))
    }, [])

    function submitView() {
        downloadRef.current.querySelector('input').value = VIEW;
        submitFormByRef(formRef)
    }

    function submitDownload() {
        downloadRef.current.querySelector('input').value = DOWNLOAD;
        submitFormByRef(formRef)
    }

    function handleValues(value, id) {
        let data = {...preset};
        data[id] = value;

        if (id === FORMAT_TYPE) {
            switch (value) {
                case 'A3': {
                    data[FORMAT_WIDTH] = 297;
                    data[FORMAT_HEIGHT] = 420;
                    break;
                }
                case 'A4': {
                    data[FORMAT_WIDTH] = 210;
                    data[FORMAT_HEIGHT] = 297;
                    break;
                }
                case 'A5': {
                    data[FORMAT_WIDTH] = 148;
                    data[FORMAT_HEIGHT] = 210;
                    break;
                }
                default: {
                    data[FORMAT_WIDTH] = rootPreset.width;
                    data[FORMAT_HEIGHT] = rootPreset.height;
                    break;
                }
            }
        } else if (id === ORIENTATION_TYPE) {
            if (value === 'landscape') {
                if (data[FORMAT_WIDTH] < data[FORMAT_HEIGHT]) {
                    data = swap(data);
                }
            } else {
                if (data[FORMAT_WIDTH] > data[FORMAT_HEIGHT]) {
                    data = swap(data);
                }
            }

        } else if (id === FORMAT_WIDTH || id === FORMAT_HEIGHT) {
            data[FORMAT_TYPE] = 'custom';
            if (data[FORMAT_WIDTH] > data[FORMAT_HEIGHT]) {
                data[ORIENTATION_TYPE] = 'landscape';
            } else {
                data[ORIENTATION_TYPE] = 'portrait';
            }
        }

        setPreset(data);
    }

    function swap(data) {
        const width = data[FORMAT_WIDTH];
        data[FORMAT_WIDTH] = data[FORMAT_HEIGHT];
        data[FORMAT_HEIGHT] = width;

        return data;
    }

    return (
        <Form ref={formRef} noValidate onSubmit={handleSubmit} className={''}>
            <div className="container">
                <p className="text-center font-weight-bold"
                   dangerouslySetInnerHTML={{ __html: t('printSettingsFor').replace(500,`<span class="${selectedList.length > 500 ? 'col-figma-accent' : ''}">${selectedList.length}</span>`) }}>
                </p>
                <div className="wmc ms-auto mw-100">
                    <p className="mb-1 text-right w-max-content mw-100"
                       dangerouslySetInnerHTML={{ __html: '*'+t('Maximum_quantity_stickers_1').replace(500,`<span class="text-bold">${maximum_quantity_stickers}</span>`)}}
                    ></p>
                    <p className="mb-1 text-right"
                       dangerouslySetInnerHTML={{ __html: '*'+t('Maximum_quantity_stickers_2').replace(500,`<span class="text-bold">${maximum_quantity_stickers}</span>`)}}
                    ></p>
                </div>
            </div>
            <table width={'100%'}>
                <tbody>
                <tr>
                    <td ref={downloadRef}><InputText hidden id={'download'} value={VIEW}/></td>
                </tr>
                <tr>
                    <td><strong>{t('mediaType')}</strong></td>
                </tr>
                <tr>
                    <td className={'p-2'}><small>{t('mediaType')}</small></td>
                    <td className={'p-2'}>
                        <Select
                            id={MEDIA_TYPE}
                            loaded={loaded}
                            selectValue={preset?.media}
                            keyName={'value'}
                            nameValue={'text'}
                            options={MediaOptions}
                            handleChange={handleValues}
                        /></td>
                </tr>
                <tr>
                    <td><Divider className={'my-1'}/></td>
                </tr>
                <tr>
                    <td><strong>{t('dimensions')}</strong></td>
                </tr>
                <tr>
                    <td className={'p-2'}><small>{upperCaseFirstLetter(t('format'))}</small></td>
                    <td className={'p-2'}>
                        <Select
                            id={FORMAT_TYPE}
                            loaded={loaded}
                            keyName={'value'}
                            nameValue={'text'}
                            options={FormatOptions}
                            selectValue={preset?.format}
                            disabled={preset.media.value === 'tape'}
                            handleChange={handleValues}
                        />
                    </td>
                    <td className={'p-2'}><small>{upperCaseFirstLetter(t('orientation'))}</small></td>
                    <td className={'p-2'}>
                        <Select
                            id={ORIENTATION_TYPE}
                            loaded={loaded}
                            keyName={'value'}
                            nameValue={'text'}
                            options={OrientationOptions}
                            selectValue={preset?.orientation}
                            disabled={preset.media.value === 'tape'}
                            handleChange={handleValues}
                        />
                    </td>
                </tr>
                <tr>
                    <td className={'p-2'}><small>{upperCaseFirstLetter(t('width'))}</small></td>
                    <td className={'p-2'}>
                        <InputText
                            loaded={loaded}
                            value={preset?.width}
                            id={FORMAT_WIDTH}
                            type={'number'}
                            handleChange={handleValues}
                        />
                    </td>
                    <td className={'p-2'}><small>{upperCaseFirstLetter(t('height'))}</small></td>
                    <td className={'p-2'}>
                        <InputText
                            loaded={loaded}
                            value={preset?.height}
                            id={FORMAT_HEIGHT}
                            type={'number'}
                            disabled={preset.media.value === 'tape'}
                            handleChange={handleValues}
                        />
                    </td>
                </tr>
                <tr>
                    <td><Divider className={'my-1'}/></td>
                </tr>
                <tr>
                    <td><strong>{upperCaseFirstLetter(t('margins'))}</strong></td>
                </tr>
                <tr>
                    <td className={'p-2'}><small>{upperCaseFirstLetter(t('left'))}</small></td>
                    <td className={'p-2'}>
                        <InputText
                            loaded={loaded}
                            value={preset?.left}
                            id={MARGINS_LEFT}
                            type={'number'}
                            handleChange={handleValues}
                        />
                    </td>
                    <td className={'p-2'}><small>{upperCaseFirstLetter(t('top'))}</small></td>
                    <td className={'p-2'}>
                        <InputText
                            loaded={loaded}
                            value={preset?.top}
                            id={MARGINS_TOP}
                            type={'number'}
                            handleChange={handleValues}
                        />
                    </td>
                </tr>
                <tr>
                    <td className={'p-2'}><small>{upperCaseFirstLetter(t('bottom'))}</small></td>
                    <td className={'p-2'}>
                        <InputText
                            loaded={loaded}
                            value={preset?.bottom}
                            id={MARGINS_BOTTOM}
                            type={'number'}
                            handleChange={handleValues}
                        />
                    </td>
                    <td className={'p-2'}><small>{upperCaseFirstLetter(t('right'))}</small></td>
                    <td className={'p-2'}>
                        <InputText
                            loaded={loaded}
                            value={preset?.right}
                            id={MARGINS_RIGHT}
                            type={'number'}
                            handleChange={handleValues}
                        />
                    </td>
                </tr>
                <tr>
                    <td><strong>{t('gapSize')}</strong></td>
                </tr>
                <tr>
                    <td className={'p-2'}><small>{upperCaseFirstLetter(t('verticalGapTitle'))}</small></td>
                    <td className={'p-2'}>
                        <InputText
                            loaded={loaded}
                            value={preset['vertical-gap'] ?? null}
                            id={VERTICAL_GAP}
                            type={'number'}
                            handleChange={handleValues}
                        />
                    </td>
                    <td className={'p-2'}><small>{upperCaseFirstLetter(t('horizontalGapTitle'))}</small></td>
                    <td className={'p-2'}>
                        <InputText
                            oaded={loaded}
                            value={preset['horizontal-gap'] ?? null}
                            id={HORIZONTAL_GAP}
                            type={'number'}
                            handleChange={handleValues}
                        />
                    </td>
                </tr>
                </tbody>
            </table>
            <ModalButtons buttons={{
                cancel: {
                    text: t('cancel'),
                    variant: 'outline-primary',
                    type: 'button',
                    action: hideModalForm,
                },
                view: {
                    text: upperCaseFirstLetter(t('view')),
                    variant: 'primary',
                    type: 'button',
                    disabled: loaded || selectedList.length > maximum_quantity_stickers,
                    action: submitView,
                },
                download: {
                    text: upperCaseFirstLetter(t('downloadPDF')),
                    variant: 'primary',
                    type: 'button',
                    disabled: loaded || selectedList.length > maximum_quantity_stickers,
                    action: submitDownload
                },
                submit: {
                    type: 'submit',
                    hidden: true
                }
            }}/>
        </Form>
    );

};

export default PrintSettingsForm;
