import React from 'react';
import { useSelector } from "react-redux";
import Lottie from 'lottie-react';
import animationData from '../../_styles/files/chinese_new_year_loader.json';

const Spinner = () => {
    // const spinnerRaccoon = useSelector(state => state.global.spinnerRaccoon);

    // return spinnerRaccoon ? (
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="flex justify-center items-center overflow-hidden rounded-full" style={{
                width: '500px',
                height: '500px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                borderRadius: '50%'
            }}>
                <Lottie
                    animationData={animationData}
                    loop={true}
                    autoplay={true}
                />
            </div>
        </div>
    );
    // ) : <div className="lds-dual-ring"/>;
};

export default Spinner;
