
import React, { useEffect } from 'react';
import { useTranslation }   from "react-i18next";
import { useDispatch }      from "react-redux";

import localStyles from './styles.module.scss'

import { hideModalForm }                    from "../../../utils/misc";

const SuccessPayment = ({hideAction}) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    function hideModal() {
        hideAction && hideAction();
        hideModalForm(dispatch)();
    }

    return (
        <div className={`${localStyles.popUpContainer} ${localStyles.successPayment} p-relative`}>
            <div className={`${localStyles.videoCuter}`}>
                <div className={`${localStyles.video} `}
                    style={{
                        background: 'url("/assets/files/success-pay.gif") center/cover no-repeat'
                    }}
                ></div>
            </div>
            <img
                onClick={hideModal}
                style={{
                    position: 'absolute',
                    top: 18,
                    right: 20,
                    zIndex: 10
                }}
                className={'my-modal-close cursor-pointer'} src="/assets/img/close.svg"
                alt="close"/>
        </div>
    );
};

export default SuccessPayment;