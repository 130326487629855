import React from "react";
import { Card } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import Crisp from '../../components/shared/modal/Crisp';
import LoginForm from "../../components/forms/LoginForm";

const Login = () => {
    const {t} = useTranslation();

    return (
        <Card className={'w-100 border-0'}>
            <p className={'text-center'} style={{
                fontWeight: 'bold',
                fontSize: '24px',
                lineHeight: '26px',
                color: '#0060AE'
            }}>{t('sign_in')}</p>
            <LoginForm/>
            <Crisp/>
        </Card>
    )
}

export default Login;
