import React, { Children }                        from 'react';
import ReactDOM                     from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation }           from "react-i18next";

import { setAlertShow, setAlertClear }             from "../../../storage/global";

const MyAlert = ({isOpen, type, title, text, withoutCancel, children, withoutIcon}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const alertGlobal = useSelector(state => state.global.alert)
    const alert = {
        show: isOpen ?? alertGlobal.show,
        title: title ?? alertGlobal.title,
        text: text ?? alertGlobal.text,
        type: type ?? alertGlobal.type,
        withoutCancel: withoutCancel ?? alertGlobal.withoutCancel,
        children: children ?? alertGlobal.children,
        withoutIcon: !!(withoutIcon ?? alertGlobal.withoutIcon),
        alertIcon: alertGlobal.alertIcon,
        hideAction: alertGlobal.hideAction,
    }

    function hide() {
        dispatch(setAlertClear(null))
        dispatch(setAlertShow(false))
        alert.hideAction && alert.hideAction();
    }

    function hideOut(e) {
        if (e.target.id === 'my-alert')
            if(!alert.withoutCancel) hide();
    }

    if (!alert.show) return null;

    if (alert.title === 'Message')
        alert.title = t('message')
    if (alert.text === 'An error has occurred. Please try again later.')
        alert.title = t('requestTimeout')

    function getIco(type) {
        switch (type) {
            case 'danger':
                return <img style={{
                    marginRight: '8px'
                }} src="/assets/img/danger.svg" alt="alert"/>;
            case 'success':
                return <img style={{
                    marginRight: '8px'
                }} src="/assets/img/success.svg" alt="alert"/>
            case 'warning':
                return <img style={{
                    marginRight: '8px'
                }} src="/assets/img/warning.svg" alt="alert"/>
            default:
                return null;
        }
    }

    return ReactDOM.createPortal(
        <div
            id={'my-alert'}
            onClick={hideOut}
            className={`my-alert d-flex justify-content-center align-items-center h-100`}
        >
            <div id={'alert-content'}
                 className={"my-alert-content overflow-auto"}
                 style={{maxHeight: '100%'}}
            >
                <>
                    {
                        alert.title && alert.title.length ?
                            <div className={'d-flex justify-content-between align-items-center'} style={{
                                marginBottom: '10px'
                            }}>
                                <span style={{
                                    fontWeight: 'bold'
                                }}>
                                    {alert.title}
                                </span>
                                <span>
                                    {alert.alertIcon ? alert.alertIcon : null}
                                    {alert.withoutCancel ? null : <img
                                        onClick={hide}
                                        className={'my-alert-close'}
                                        src="/assets/img/close.svg"
                                        alt="close"
                                    />}
                                </span>
                            </div>
                            : null
                    }
                </>
                <>
                    <div className={'d-flex justify-between-start align-items-center'}>
                        {!alert.withoutIcon && <span className={'d-flex align-items-center'}>{getIco(alert.type)}</span>}
                        <span dangerouslySetInnerHTML={{ __html: alert.text }}></span>
                    </div>
                </>
                {alert.children || null}
            </div>
        </div>
        , document.body);
};

export default MyAlert;
