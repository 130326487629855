import React, { useEffect, useState } from 'react';

const Crisp = ({ withOffset = false }) => {
    const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => {
        const setCrispStyles = () => {
            const crispFrame = document.querySelector('.crisp-client');
            if (crispFrame && withOffset) {
                const styleSheet = document.createElement('style');
                styleSheet.setAttribute('data-crisp-styles', '');
                styleSheet.textContent = `
                    .crisp-client .cc-1brb6 .cc-1yy0g .cc-1m2mf {
                        bottom: 100px !important;
                    }
                    .crisp-client .cc-1brb6[data-full-view=true] .cc-1yy0g .cc-1m2mf {
                        bottom: 110px !important;
                    }
                `;
                document.head.appendChild(styleSheet);
                return styleSheet;
            }
            return null;
        };

        const styleInterval = setInterval(() => {
            const styleSheet = setCrispStyles();
            if (styleSheet) {
                clearInterval(styleInterval);
            }
        }, 100);

        if (scriptLoaded) {
            return;
        }

        window.$crisp = [];
        window.CRISP_WEBSITE_ID = "86ac8b7d-d289-46fc-aa25-fd8636db6754";

        const script = document.createElement('script');
        script.src = "https://client.crisp.chat/l.js";
        script.async = 1;

        script.onload = () => {
            setScriptLoaded(true);

            if (window.$crisp) {
                window.$crisp.push(["do", "chat:show"]);
                window.$crisp.push(["do", "trigger:show"]);
            }

            window.$crisp.push(["on", "session:loaded", () => {
                window.$crisp.push(["do", "chat:show"]);
                window.$crisp.push(["do", "trigger:show"]);
            }]);
        };

        document.head.appendChild(script);

        return () => {
            if (window.$crisp) {
                window.$crisp.push(["off", "session:loaded"]);
            }
            clearInterval(styleInterval);

            // Clean up styles when component unmounts
            const existingStyle = document.querySelector('style[data-crisp-styles]');
            if (existingStyle) {
                existingStyle.remove();
            }
        };
    }, [scriptLoaded, withOffset]);

    return null;
};

export default Crisp;
