import React, { useEffect, useCallback } from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

import { getList } from "../../utils/misc";
import HelpVideo from "./HelpVideo";
import ExcelTemplateAndImport from "./ExcelTemplateAndImport";
import BalanceButton from "./BalanceButton";
import NotifBtn from "./NotifBtn";
import LangBlock from "../shared/LangBlock";
import ProfileButton from "./ProfileButton";
import LogoutButton from "./LogoutButton";
import WriteReport from "./WriteReport";
import GoToSite from "./GoToSite";
import CreateNewParcel from "../../pages/unidentified_parcels/СreateNewParcel.jsx";

import NewParcelForm from "../../components/forms/NewParcelForm";
import { setModalForm } from "../../storage/global";

const HeaderLarge = ({
    className,
    onBackToWebsite,
    onFeedbackClick,
    onInstructionClick,
    onLanguageChange,
    onLogout,
    onNotificationClick,
    onProfileClick,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCreateNewParcel = useCallback(() => {
        dispatch(setModalForm({
            title: '',
            form: <NewParcelForm
                getListParcels={() => getList(dispatch, t)}
                navigate={navigate}
            />,
            fullScreen: true,
            hideOut: false,
        }));
    }, [dispatch, t]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const actionParam = urlParams.get('action');

        if (actionParam === 'create-new-parcel') {
            handleCreateNewParcel();
        }
    }, [handleCreateNewParcel]);

    const handleClick = useCallback(() => {
        handleCreateNewParcel();
    }, [handleCreateNewParcel]);

    return (
        <div className={`row no-gutters ${className}`}>
            <div className={'d-flex align-items-center justify-content-start col-auto'}>
                <HelpVideo
                    className={'mr-2'}
                    onClick={onInstructionClick}
                />
                <ExcelTemplateAndImport className={'excel-template-import'}/>
                <CreateNewParcel
                    className="mr-2 create-new-parcel-btn_header"
                    handle={handleClick}
                    classNameBtn="create-new-parcel-btn"
                />
            </div>
            <div className={"d-flex align-items-center justify-content-end col"}>
                <GoToSite
                    classNameContainer={'mr-08vw'}
                    onClick={onBackToWebsite}
                />
                <WriteReport
                    classNameContainer={'d-flex align-items-center mr-08vw'}
                    underText={t('Feedback')}
                    onClick={onFeedbackClick}
                />
                <BalanceButton isLargeHeader={true}/>
                <NotifBtn
                    classNameContainer={'ml-08vw'}
                    onClick={onNotificationClick}
                />
                <LangBlock
                    className={'fs-13px ml-08vw'}
                    onChange={onLanguageChange}
                />
                <ProfileButton
                    className={'cursor-pointer h28 ml-08vw'}
                    classNameCenter={'mt--10px'}
                    onClick={onProfileClick}
                />
                <LogoutButton
                    className={'ml-2'}
                    onClick={onLogout}
                />
            </div>
        </div>
    );
};

export default HeaderLarge;
