import * as amplitude from '@amplitude/analytics-browser';

const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY;

amplitude.init(AMPLITUDE_API_KEY, undefined, {
    defaultTracking: {
        sessions: true,
        pageViews: true,
        formInteractions: true,
        fileDownloads: true
    },
    logLevel: 0, // Equivalent to amplitude.Types.LogLevel.None
    serverUrl: `https://${process.env.REACT_APP_AMPLITUDE_ENDPOINT}/2/httpapi`,
    disableLogLevel: true,
    transportProvider: {
        send: () => Promise.resolve({ status: 200, statusText: 'OK' })
    }
});

// Enhanced tracking function with better error handling and user properties
export const trackEvent = (eventType, eventProperties = {}) => {
    try {
        // Get user data from local storage or other source
        const userId = localStorage.getItem('user_id');
        const userProfile = localStorage.getItem('user_profile');

        const defaultProperties = {
            page: window.location.pathname,
            timestamp: new Date().toISOString(),
            user_id: userId || 'anonymous',
            environment: process.env.NODE_ENV,
            app_version: process.env.REACT_APP_VERSION,
            platform: 'web',
            device_type: window.innerWidth < 1200 ? 'mobile' : 'desktop',
            language: localStorage.getItem('i18nextLng') || 'en'
        };

        // Set user properties if available
        if (userProfile) {
            const profile = JSON.parse(userProfile);
            const identify = new amplitude.Identify();
            identify.set('company_name', profile.company_name);
            identify.set('user_type', profile.user_type);
            identify.set('currency', profile.currency);
            amplitude.identify(identify);
        }

        amplitude.track(eventType, {
            ...defaultProperties,
            ...eventProperties
        });
    } catch (error) {}
};

export const AMPLITUDE_EVENTS = {
    // Filter events
    FILTER_NEW_PARCELS: 'filter_new_parcels',
    FILTER_WAITING_ARRIVAL: 'filter_waiting_arrival',
    FILTER_SENT_PARCELS: 'filter_sent_parcels',

    // New parcels events
    ADD_NEW_NEW_PARCELS: 'add_new_new_parcels',
    EDIT_NEW_PARCELS: 'edit_new_parcels',
    EXPORT_NEW_PARCELS: 'export_new_parcels',
    PRINT_STICKER_NEW_PARCELS: 'print_sticker_new_parcels',

    // Parcel actions
    SELECT_PARCEL: 'select_parcel',
    SELECT_ALL_PARCELS: 'select_all_parcels',
    VIEW_PARCEL_DETAILS: 'view_parcel_details',

    // Navigation events
    COPY_ADDRESS: 'copy_address',
    CONTACT_US: 'contact_us',
    NEWS_CLICK: 'news_click',
    REF_PROMOCODE_CLICK: 'ref_promocode_click',
    FEEDBACK_CLICK: 'feedback_click',
    BACK_TO_WEBSITE_CLICK: 'back_to_website_click',
    PROMOCODES_ARTICLES_CLICK: 'promocodes_articles_click',
    INSTRUCTION_CLICK: 'instruction_click',
    PAYMENT_INITIATED: 'payment_initiated',
    CopyPAYMENT_INITIATED: 'payment_initiated',
    SELECT_FOR_PAYMENT: 'select_for_payment',
    NOTIFICATION_CLICK: 'notification_click',
    NOTIFICATION_CLOSE: 'notification_close',
    PROFILE_CLICK: 'profile_click',
    MENU_CLOSE: 'menu_close',
    LANGUAGE_CHANGE: 'language_change',
    USER_LOGOUT: 'user_logout',
    FOOTER_TOGGLE: 'footer_toggle',
    CAROUSEL_NAVIGATION: 'carousel_navigation',
    EDIT_WAITING_ARRIVAL: 'edit_waiting_arrival',
    PRINT_STICKER_WAITING_ARRIVAL: 'print_sticker_waiting_arrival',
    PRINT_STICKER_SENT_PARCELS: 'print_sticker_sent_parcels',
};

export default {
    trackEvent,
    AMPLITUDE_EVENTS
};
