import React, { useState, useMemo } from 'react';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import { trackEvent, AMPLITUDE_EVENTS } from "../../utils/amplitudeTracker";


const CarouselElement = (props) => {

    const {t} = useTranslation();
    const isMobile = useSelector(state => state.global.isMobile)

    const [show, setShow] = useState(false);

    const title = useMemo(() => {
        var e = document.createElement('textarea');
        e.innerHTML = props.title?.rendered;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }, [props.title?.rendered]);

    function closeCarousel() {
        // sessionStorageService.setShowSlide('hidden')
    }

    function viewMore(event) {
        switch (event.button) {
            case 0: {
                event.preventDefault();
                event.stopPropagation();

                // Track news click
                trackEvent(AMPLITUDE_EVENTS.NEWS_CLICK, {
                    news_id: props.id,
                    news_title: title,
                    news_link: props.link
                });

                document.getElementById('new_id_' + props.id).removeAttribute("href");
                let a = document.createElement('a');
                a.target = '_blank';
                a.href = props.link;
                a.click();
                break;
            }
            default: break;
        }
        setShow(!show);
        return false;
    }

    return (
        <div className={`container-new row mx-0`} key={props.ind}>
            <div className="col-3">
                <div className="col-auto d-flex align-items-center justify-content-center h-100">
                    <img className={'mr-1 h-100'} src={props.fimg_url} alt="export-button"/>
                </div>
            </div>
            <div className={'col-9 px-0'}
                 style={{maxWidth: 1000}}
            >
                <a href={props.link} id={'new_id_' + props.id} onMouseDown={(event) => viewMore(event)} style={{textDecoration: 'none'}} className={'col-figma-text-primary'}>
                    <p className={'fs-16 fw-700 mb-1 two-line-text'} style={{lineHeight: '20px'}}><strong>{title}</strong></p>
                    {/* {
                        props.content?.rendered ?
                            <p key={'1'} id={props.content?.rendered} className={'m-0 fs-14 text-minimise'}><span
                                dangerouslySetInnerHTML={{__html: props.content?.rendered}}/></p>
                            : null
                    } */}
                    {!isMobile && <p key={'end'} id={'end'} className={'m-0 fs-14 one-line-text text-decoration-underline-hover'}>
                        <span>{t('Explore_more_news')}</span>
                    </p>}
                </a>
            </div>
        </div>
    );
};

export default CarouselElement;
