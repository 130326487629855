/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { useTranslation }   from "react-i18next";
import { useDispatch }      from "react-redux";

import localStyles from './styles.module.scss'

import { hideModalForm } from "../../../utils/misc";

const SuccessCreate = ({list, setList}) => {
    let isMounted = true;

    const dispatch = useDispatch();
    const {t} = useTranslation();

    useEffect(() => {
        setTimeout(() => {
            isMounted && hideModal();
        }, 3000);
        return () => {
            isMounted = false;
        }
    }, [])

    function hideModal() {
        hideModalForm(dispatch)()
    }

    return (
        <div className={`${localStyles.popUpContainer} p-relative`}>
            <div className={`${localStyles.videoCuter} `}>
                <div className={`${localStyles.video} `}
                    style={{
                        background: 'url("/assets/files/success-create.gif") center/cover no-repeat'
                    }}
                ></div>
            </div>
            <div className={`container p-absolute ${localStyles.title}`}>
                <p className="text-center font-weight-bold col-figma-text-primary">{t('Package_Created_Successfully')}</p>
            </div>
            <img
                onClick={hideModal}
                style={{
                    position: 'absolute',
                    top: 18,
                    right: 20,
                    backgroundColor: 'white',
                    zIndex: 10
                }}
                className={'my-modal-close cursor-pointer'} src="/assets/img/close.svg"
                alt="close"/>
        </div>
    );
};

export default SuccessCreate;