import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import { useNavigate } from "react-router-dom";

import localStyles from './styles.module.scss'
import {
    setAlertShow,
    setList,
    setCurrentPage,
    setModalForm,
    setSelectFromOut,
    setlostParsels,
    setLoadSpinner,
    SET_LOAD_SPINNER_RACCOON,
} from "../../storage/global";

import {
    DATE,
    DESC,
    routesNames, SELECTED,
    SUM_2,
    TrackNumber,
    Data,
    Weight
} from "../../constants";

import ActionBlock from "./ActionBlock";
import moment from "moment/moment";
import CheckBox from "../../components/input/CheckBox";
import NewParcelForm from "../../components/forms/NewParcelForm";
import PrintSettingsForm from "../../components/forms/PrintSettingsForm";
import SentParcelDetailForm from "../../components/forms/SentParcelDetailForm";
import PageListTemplate from "../../components/shared/PageListTemplate";
import PageMenu from "../../components/shared/PageMenu";
import ExportButton from "../../components/shared/pages_buttons/ExportButton";
import ButtonStyleAction from "../../components/shared/pages_buttons/ButtonStyleAction";
import CheckboxList from "../../components/shared/CheckboxList";
import PrintStickerButton from "../../components/shared/pages_buttons/PrintStickerButton";
import SpinnerRaccoon from "../../components/shared/SpinnerRaccoon";

import { exportSentParcels, getLostParsels, refuseLostParcel } from "../../utils/api";
import { sleep } from '../../utils/misc.js';

const UnidentifiedParcels = () => {

    let isMounted = true;

    const order = {
        direction: DESC,
        name: DATE
    };

    const date = moment();

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const list = useSelector(state => state.global.list);
    const uid = useSelector(state => state.global.profile.useruid);
    const selectFromOut = useSelector(state => state.global.lost.selectFromOut);

    const [checkedAll, setCheckedAll] = useState(false);

    useEffect(() => {
        dispatch(setCurrentPage(routesNames.UNIDENTIFIED_PARCELS))
        dispatch(setLoadSpinner(true));
        dispatch({type: SET_LOAD_SPINNER_RACCOON, payload: true});
        getLostParsels({id: uid})
            .then((res) => {
                if (res.status === 200 && res.data) {
                    isMounted && dispatch(setList(res.data.map(parcel => {
                        parcel.list_type = 'unidentified-parcels';
                        return parcel;
                    })));
                }
            })
            .catch(({response}) => {
                console.log('getLostParsels catch: ', response)
            })
            .finally(() => {
                dispatch(setLoadSpinner(false));
                dispatch({type: SET_LOAD_SPINNER_RACCOON, payload: false});
            })

        return () => {
            dispatch(setModalForm({show: false}))
            dispatch(setAlertShow(false))
            dispatch(setList([]))
            isMounted = false;
        }
    }, [])

    useEffect(() => {
        if(selectFromOut && list.length && list[0].list_type === 'unidentified-parcels') {
            dispatch(setList(list.map(parcel => {
                if (selectFromOut === parcel.TrackNumber) parcel.check = true;
                else parcel.check = false;
                return parcel;
            })));
            dispatch(setSelectFromOut(''));
        }
    }, [list, selectFromOut])

    function handleCheck(value, item, ref) {
        dispatch(setList(list.map(parcel => {
            if (item.TrackNumber === parcel.TrackNumber)
                parcel.check = !parcel.check;
            return parcel;
        })));
    }

    function handleAllChek(value, id, ref) {
        const newList = [...list];
        list.forEach(parcel => {
            const numEl = list.findIndex(el => el.TrackNumber === parcel.TrackNumber)
            if (numEl >= 0) newList[numEl].check = value;
        });
        dispatch(setList(newList));
        setCheckedAll(value)
    }

    const listHead = [
        {
            name: SELECTED,
            col: 1,
            text: '+',
            sortArrow: false,
            getChildren: (v, k) => {
                return <CheckBox
                    id={'all-check'}
                    className={'check-input'}
                    styles={{fontSize: '20px'}}
                    handle={handleAllChek}
                    value={checkedAll}
                />
            },
            classNames: 'justify-content-between'
        },
        {
            name: TrackNumber,
            col: 3,
            sortArrow: false,
            text: t('Domestic_express_tracking'),
            classNames: 'flex-1-0 justify-content-start'
        },
        {
            name: Data,
            col: 2,
            sortArrow: false,
            text: t('Date_receiving_in_warehouse'),
            classNames: 'flex-1-0 justify-content-start'
        },
        {
            name: Weight,
            col: 2,
            sortArrow: false,
            text: t('weight'),
            classNames: 'flex-1-0 justify-content-start'
        },
    ]

    const listItem = [
        {
            name: SELECTED,
            col: 1, isTrackNumber: false,
            getChildren: (parcel) => {
                return <CheckBox
                    className={'check-input mt-children-input-0'}
                    styles={{fontSize: '20px'}}
                    handle={(value, id, ref) => handleCheck(value, parcel, ref)}
                    value={!!parcel.check}
                />
            }
        },
        {
            name: TrackNumber,
            col: 3,
            isTrackNumber: false,
            copy: true,
            classNames: 'flex-1-0 justify-content-start',
            classNamesChildren: `${localStyles.copy}`
        },
        {name: Data, col: 2, isTrackNumber: false, classNames: 'flex-1-0 justify-content-start'},
        {name: Weight, col: 2, text: t('weight'), classNames: 'flex-1-0 justify-content-start'},
    ]

    const listItemMobile = {
        firstColumn: [
            {
                name: TrackNumber,
                nameColumn: t('tracking'),
                col: 24,
                copy: true,
                isTrackNumber: false,
                classNamesChildren: `${localStyles.children}`
            },
            {name: Data, nameColumn: t('date_sent'), col: 24, isTrackNumber: false},
        ],
        secondColumn: [
            {
                name: SELECTED, nameColumn: t(''), col: 24, isTrackNumber: false, classTableItem: 'w-100',
                getChildren: (parcel) => {
                    return <div className={'d-flex justify-content-between'}>
                        <div className={'text-minimise '}>
                            <span style={{
                                color: parcel.is_paid === 'true' ? '#2da771' : '#EC1944',
                                fontSize: '10px'
                            }}>{parcel[SUM_2]}</span>
                        </div>
                        <CheckBox
                            className={'check-input'}
                            styles={{fontSize: '20px'}}
                            handle={(value, id, ref) => handleCheck(value, parcel, ref)}
                            value={!!parcel.check}
                        />
                    </div>
                }
            },
            {name: Weight, nameColumn: t('weight'), col: 8, isTrackNumber: false},
        ]
    }

    function handleCreate() {
        dispatch(setModalForm({
            title: '',
            form: <NewParcelForm getListParcels={ null } afterCreate={afterCreate}/>,
            fullScreen: true,
            hideOut: false,
        }))
    }

    function afterCreate() {
        navigate(`/${routesNames.NEW_PARCELS}`);
    }

    async function handleRefuse() {
        const selectedList = list.filter( (el) => el.check).map( (el) => el.TrackNumber);
        selectedList.forEach( async (TrackNumber, i) => {
            await refuseLostParcel({
                id: uid,
                TrackNumber
            })
        })
        await sleep(1000);
        dispatch(setModalForm({show: false}))
        dispatch(setAlertShow(false))
        dispatch(setList([]))
        getLostParsels({id: uid})
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(setlostParsels(res.data));
                    isMounted && dispatch(setList(res.data));
                    if (!res.data.length) {
                        navigate(`/${routesNames.NEW_PARCELS}`);
                    }
                }
            })
            .catch(({response}) => {
                console.log('handleRefuse getLostParsels catch: ', response)
            })
    }

    return (
        <div className={'container-page h-100 p-relative'}>
            <SpinnerRaccoon/>
            <ActionBlock
                handleCreate={handleCreate}
                handleRefuse={handleRefuse}
            />
            <PageListTemplate
                srcOrder={order}
                uniqueRowId={'TrackNumber'}
                classesRow={'cursor-pointer'}
                listHead={listHead}
                listItem={listItem}
                listItemMobile={listItemMobile}
                emptyListText={''}
                disableSelect
                headList2={true}
            />
        </div>
    )
}

export default UnidentifiedParcels;
