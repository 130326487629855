import React                        from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button }                   from "react-bootstrap";
import { useTranslation }           from "react-i18next";

import { setSelectedList }          from "../../../storage/global";
import { selectList, unselectList } from "../../../utils/misc";

const SelectAllButton = ({className, handle, isSelectedAll, classNameBtn = ''}) => {

    const {t} = useTranslation();

    const selectText = t('selectAll');
    const unselectText = t('unselectAll');

    const dispatch = useDispatch();
    const selectedList = useSelector(state => state.global.selectedList)
    const list = useSelector(state => state.global.list)

    function onClick(e) {
        if (typeof handle === 'function')
            handle(e);

        // if (selectedList.length) {
        //     dispatch(setSelectedList([]))
        //     unselectList(selectedList, true)
        // } else if (list) {
        //     dispatch(setSelectedList(list))
        //     selectList(list)
        // }

    }

    return (
        <div onClick={onClick} className={`${className ? className : ''}`}>
            <Button variant={'outline-primary'}
                    className={`select-all-btn-max-w-360 ${classNameBtn}`}
            >
                <span>{selectedList.length || isSelectedAll ? unselectText : selectText}</span>
            </Button>
        </div>
    );
};

export default SelectAllButton;
