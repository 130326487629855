import React, { useEffect, useState }       from 'react';
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useDispatch }                      from "react-redux";
import { useTranslation }           from "react-i18next";
import {t} from "i18next";
import { getCookie, setCookie } from "../../utils/cookie";

import { setLoad,setModalForm, setShowMenu }    from "../../storage/global";
import { routesNames }                          from "../../constants";
import Logo                                     from "../../components/shared/Logo";
import LangBlock                                from "../../components/shared/LangBlock";
import Copyright                                from "../../components/shared/Copyright";
import { getUserInfo, makeGetRequest }          from "../../utils/api";
import { goToSite, downloadWeChatQR }                             from "../../utils/misc";
import Crisp from "../../components/shared/modal/Crisp";

const RootAuth = () => {
    const {t, i18n} = useTranslation();
    const lang = i18n.language || 'en';

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentLocation = useLocation().pathname;
    const [isAccess, setData] = useState(false);

    useEffect(() => {

        const params = new URLSearchParams(window.location.search);
        const langParam = params.get('lang');
        const actionParam = params.get('action');
        const langCookie = getCookie('language');

        if (langParam && !langCookie) {

            setCookie('language', langParam, 365);

            const newUrl = `${window.location.pathname}?${params}`;
            window.location.href = newUrl;
            return;
        }

        dispatch(setLoad(true));
        getUserInfo()
            .then(response => {
                if (actionParam) {
                    navigate(`/${routesNames.NEW_PARCELS}?action=${actionParam}`);
                } else {
                    navigate(`/${routesNames.NEW_PARCELS}`);
                }
            })
            .catch(({response}) => {
                if (!response || response.status === 401) {
                    if(actionParam) {
                        const loginPath = `/${routesNames.AUTH}/${routesNames.LOGIN}`;
                        const loginUrl = `${loginPath}?${params}`;
                        navigate(loginUrl);
                    }
                }
            })
            .finally(() => {
                setData(true);
                dispatch(setLoad(false));
            });
    }, []);


    function openQR() {
        makeGetRequest(`/api/misc/showContactUsQR?source=${currentLocation.includes(`/${routesNames.SIGNUP}`) ? 'registrationFormQRCode' : 'loginFormQRCode'}`).catch(err => {
            console.error('makeGetRequest err');
        });

        dispatch(setModalForm({
            title: '',
            form: <div className={'text-bold flex-column w-100 d-flex justify-content-center mt-5 mt-xl-0'}>
                <p className={'mx-auto text-center'}>{t('scan_QR_auth')}</p>
                <img className={'mx-auto'} src={`/assets/img/WeChatQR.jpg`} alt=""/>
            </div>,
            formContent: {classNames: 'modal-form-content-profile'},
            formBody: {classNames: 'd-flex mb-30'},
            styles: {background: 'rgba(0, 0, 0, 0.9)'},
        }))
    }

    return (
        <>
            {
                isAccess
                // !load
                    ?
                    <div className="container-fluid h-100 d-flex flex-column">
                        <div className="z-index-2 row align-items-center pt-3 p-lg-4 mb-3">
                            <div className="col">
                                <Crisp/>
                                <Logo goTo={() => goToSite(lang)}/>
                            </div>
                            <div className="col d-flex justify-content-end">
                                <LangBlock className={'text-left'}/>
                            </div>
                        </div>
                        <div className="h-100 d-flex row justify-content-center align-items-center login-form-top">
                            <div className={''}
                                 style={{
                                     marginTop: '42px',
                                     maxWidth: '476px',
                                 }}
                            >
                                <div className={'p-3 pb-3 px-md-5 pt-md-4'}
                                     style={{
                                         backgroundColor: '#FFFFFF',
                                         borderRadius: '16px',
                                         boxShadow: '0px 1px 2px rgba(23, 23, 37, 0.12)',
                                     }}
                                >
                                    <Outlet/>
                                </div>
                                <Copyright/>
                                {/*<div className="root-auth-qr d-flex d-xl-none p-3 py-2 text-bold flex-column">*/}
                                {/*    <p className={'d-block mx-auto text-center mb-2'}>{t('scan_QR_auth')}</p>*/}
                                {/*    <img className={'mx-auto'} onClick={() => {downloadWeChatQR()}} src={`/assets/img/WeChatQR.jpg`} alt=""/>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        {/*<div className="root-auth-qr d-none d-xl-flex p-3 text-bold flex-column" onClick={openQR}>*/}
                        {/*    <p className={'d-block mx-auto'}>{t('scan_QR_auth')}</p>*/}
                        {/*    <img className={'mx-auto cursor-pointer'} src={`/assets/img/WeChatQR.jpg`} alt=""/>*/}
                        {/*</div>*/}
                    </div>
                    : null
            }
        </>
    );
}
;

export default RootAuth;
