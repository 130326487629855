import React                from 'react';
import { useDispatch }      from "react-redux";
import { useTranslation }   from "react-i18next";

import ImportForm           from "../../components/forms/ImportForm";
import ImportButton         from "../../components/shared/pages_buttons/ImportButton";
import { setModalForm }     from "../../storage/global";

const ExcelTemplateAndImport = ({className}) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    function downloadTemplate() {
        const a = document.createElement('a')
        a.target = '_blank';
        a.href = '/assets/files/download_list_form.xls';
        a.click();
    }

    function handleImport() {
        dispatch(setModalForm({
            title: t('choose_file'),
            form: <ImportForm/>,
        }))
    }

    return (
        <div className={className}>
            <div onClick={downloadTemplate} className={'d-flex align-items-center cursor-pointer'}>
                <img src="/assets/img/download.svg" alt="download"/>
                <span className={'my-text-primary ml-1 fs-13px'}>{t('Download_Application_Template_Example')}</span>
            </div>
            <ImportButton handle={handleImport} className={''} text={t('importFromExcel')} classNameBtn={'text-style-1 fs-13px'}/>
        </div>
    );
};

export default ExcelTemplateAndImport;
