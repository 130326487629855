import React, { useState, useRef } from 'react';

const PageMenu = ({className, children, stylesMenuList}) => {

    const dragItem = useRef();

    const [showMenu, setShowMenu] = useState(false)

    function openMenu() {
        setShowMenu(!showMenu);
    }

    function detectLeftBtn(e: any) {
        const ev: any = e || window.event;
        if('buttons' in ev) {
            return ev.buttons === 1;
        }
        const button = e.which || e.button;
        return button === 1;
    }

    function startMob(eEl: any) {
        if(!detectLeftBtn(eEl)) return;
        let x = eEl.clientX;
        let y = eEl.clientY;
        const moveField: any = document.getElementById('main');
        moveField.onpointermove = dragMove;
        moveField.onpointerup = dropMob;

        function dragMove(e: any) {
            const difX = e.clientX - x;
            const difY = e.clientY - y;
            const right = +dragItem.current.style.right.slice(0, -2)
            const bottom = +dragItem.current.style.bottom.slice(0, -2)
            dragItem.current.style.right = (right - difX) + 'px';
            dragItem.current.style.bottom = (bottom - difY) + 'px';
            x = e.clientX;
            y = e.clientY;
        }
        function dropMob(e: any) {
            moveField.onpointermove = null;
            moveField.onpointerup = null;
        }
    }

    return (
        <div className={`${className ? className : ''} page-menu cursor-pointer d-mobile ${showMenu ? 'page-menu-active' : ''}`}
            style={{
                bottom: 10 + 'px',
                right: 14 + 'px'
            }}
            ref={dragItem}
            draggable={true}
            onPointerDown={(e) => {startMob(e)}}
            onDragStart={() => false}
        >
            <div className={'w-100 h-100 position-relative'}>
                <div onClick={openMenu} className={'h-100 w-100'}>
                    <div id={'dot-1'}/>
                    <div id={'dot-2'}/>
                    <div id={'dot-3'}/>
                </div>
                <div style={{
                    position: 'absolute',
                    bottom: '60px',
                    right: '0',
                    borderRadius: '16px',
                    padding: '15px',
                    boxShadow: '0px 4px 24px rgba(23, 23, 37, 0.12)',
                    background: '#FFFFFF',
                    ...stylesMenuList,
                }} className={`d-flex flex-column justify-content-around list-page-menu ${showMenu ? '' : 'opacity-0 visibility-hidden'}`}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default PageMenu;
