
import React, { useState, useMemo }  from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation }           from "react-i18next";
import ReactTooltip                 from "react-tooltip";

import ButtonSmallNoBorder          from "../ButtonSmallNoBorder";
import { viewAlert }                from "../../../utils/misc";


const PrintStickerButton = ({className, handle, checkProcessed}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const list = useSelector(state => state.global.list)
    const [ref, setRef] = useState({});

    const count = useMemo(() => list.filter(parcel => parcel.check).length, [list])

    const disabled = useMemo(() => {
        if(!checkProcessed) return false;
        return list.filter(parcel => parcel.check).some(parcel => parcel.Processed === "No");
    }, [list, checkProcessed])

    function onClick(event) {
        if (!count) {
            viewAlert(dispatch, {status: 400}, {text: t('nothingSelected'), type: 'warning'});
            return;
        }

        if (typeof handle === 'function' && !disabled) {
            handle();
        }
    }

    return (
        <div onClick={onClick}
            ref={ref => setRef(ref)}
            className={`${className ? className : ''}`}
            onMouseOver={event => !disabled && ReactTooltip.show(ref)}
            onMouseOut={event => !disabled && ReactTooltip.hide(ref)}
            data-tip={`<div class="wb-normal">${t('parcel_is_being_processed')}</div>`}
            data-html="true"
        >
            <ButtonSmallNoBorder disabled={disabled}>
                <img className={'mr-1'} src="/assets/img/print.svg" alt="print-button"/>
                <span>{t('print_sticker')}</span>
            </ButtonSmallNoBorder>
        </div>
    );
};

export default PrintStickerButton;
