/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Form} from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import {useTranslation} from "react-i18next";
import SelectRS from 'react-select';

import {
    createUpdateNewParcel,
    getDangerousGoods,
    getDeniedZip,
    getCostInsurance,
    getGoodTypeByCommodity,
    getExcludedGoods,
    getParcelInfo,
    sendToWarehouse,
    getForbiddenCommodity,
    makeGetRequest,
    makePostRequest,
} from "../../utils/api";
import {
    boolValidate,
    checkOnNum,
    customValidate,
    emailValidate,
    emailSpecialCharactersValidate,
    excludedValidate,
    letterValidate2,
    minMaxValue,
    noEmptyValidate,
    numberFloatValidate,
    numberValidate,
    validateLength,
    letterSpaceHyphenValidate,
    letterNumberValidate,
    phoneValidate,
    letterValidateSpace6Languages,
    letterValidateSpecialSigns6Languages,
    letterValidateSpace,
    letterNumberSpaceValidate,
    phoneSpaceValidate,
    letterNumberHyphenValidate,
    letterNumberSpaceHyphenSlashValidate,
    streetValidate6Languages,
    wordCountWithLetterCount,
    curpValidation,
} from "../../utils/validate";
import {
    checkValidate,
    hideModalForm,
    hideModalForm2,
    serializeFormToObject,
    debounce,
    viewAlert
} from "../../utils/misc";
import {
    ADDRESS,
    CITY,
    COMENT,
    COMMODITY,
    COUNTRY,
    DEFAULT_ERROR_OBJECT,
    EMAIL,
    HS_CODE,
    INSURANCE_VALUE,
    IOSS_NUMBER,
    PASSPORT,
    PASSPORT_NUMBER,
    PRICE,
    QTY,
    RECEIVER,
    REGION,
    SEND_BY,
    SENSITIVE_GOODS,
    SERVICE_TYPE,
    SKU,
    TAX_ID_NUMBER,
    TELEPHONE,
    TO_COUNTRY,
    URL,
    WEIGHT,
    CODE,
    DATE,
    TRACKER,
    WAREHOUSE,
    TRACK_NUMBER,
    TRACK,
    WAREHOUSE_FULL,
    CNTS,
    ZIP,
    ASSOCIATED_BARCODES,
} from "../../constants";

import Select from "../input/Select";
import Switch from "../input/Switch";
import InputText from "../input/InputText";
import InfoQuestion from "../shared/InfoQuestion";
import ModalButtons from "../shared/modal/ModalButtons";
import SuccessCreate from "../shared/PopUpsSuccess/SuccessCreate";
import {setAlert,
    setList,
    setLoadSpinner,
    setSelectedList,
    setAlertShow,
    setAlertClear,
    RECEIVERS,
    setModalForm2,
    defModalForm,
    setModalForm,
} from "../../storage/global";

const FORM_ID = 'new-parcel-form';

const validations = {};
let savedIOSS: boolean = false;

const NewParcelForm = ({data, isEdit, getListParcels, afterCreate, disableFields, page, navigate}) => {

    const {t, i18n} = useTranslation();

    const profile = useSelector(state => state.global.profile)
    const receivers = useSelector(state => state.global.receivers)
    const lang = useSelector(state => state.global.locale)
    const insuranceCountries = useSelector(state => state.global.insuranceCountries)
    const rules = useSelector(state => state.global.rules);
    const countryListRedux = useSelector(state => state.global.countryList);
    const europeCountry_ = useSelector(state => state.global.europeCountries);
    const countries = useSelector(state => state.global.countries);
    const loadSpinner = useSelector(state => state.global.spinner)

    const dispatch = useDispatch();

    const defSendBy = {value: '', text: t('selectSendBy')}
    const defGoodType = {value: '', text: t('selectGoodType')}
    const defCountry = {country_id: '', country_name: t('selectCountry')}
    const defServiceTypes = [{value: "1", text: t('PUDO')}, {value: "0", text: t('toDoor')}]
    const defInsurance = {
        InsuranceCost: 0,
        currency: "",
        description: ""
    }

    if (Object.values(validations).length === 0) {
        validations[TO_COUNTRY] = {
            empty: noEmptyValidate(t('validEmpty'))
        }
        validations[SENSITIVE_GOODS] = {
            empty: noEmptyValidate(t('validEmpty'))
        }
        validations[SEND_BY] = {
            empty: noEmptyValidate(t('validEmpty'))
        }
        validations[SERVICE_TYPE] = {
            empty: noEmptyValidate(t('validEmpty'))
        }
        validations[CITY] = {
            empty: noEmptyValidate(t('validEmpty')),
            length: validateLength(3, null, t('validLength_2').replace(/(\$\{1\$\})/, 3)),
            letter: letterValidateSpace6Languages(t('validOnlyLetter')),
        }
        validations[REGION] = {
            empty: noEmptyValidate(t('validEmpty')),
            length: validateLength(3, null, t('validLength_2').replace(/(\$\{1\$\})/, 3)),
            letter: letterValidateSpace6Languages(t('validOnlyLetter')),
        }
        validations[ZIP] = {
            empty: noEmptyValidate(t('validEmpty')),
            length: validateLength(4, null, t('validLength_2').replace(/(\$\{1\$\})/, 4)),
            letter: letterNumberValidate(t('validValueIncorrect')),
        }
        validations[COMMODITY] = {
            empty: noEmptyValidate(t('validEmpty')),
            length: validateLength(2),
            letter: letterSpaceHyphenValidate(t('validOnlyLetter')),
        }
        validations[QTY] = {
            empty: noEmptyValidate(t('validEmpty')),
            number: numberFloatValidate(t('validFloat'))
        }
        validations[PRICE] = {
            empty: noEmptyValidate(t('validEmpty')),
            number: numberFloatValidate(t('validFloat'))
        }
        validations[WEIGHT] = {
            empty: noEmptyValidate(t('validEmpty')),
            number: numberFloatValidate(t('validFloat'))
        }
        validations[IOSS_NUMBER] = {
            empty: noEmptyValidate(t('validEmpty')),
            custom: customValidate(/^(IM)[0-9]{10}$/, t('validIossNumber_1'))
        }
        validations[RECEIVER] = {
            empty: noEmptyValidate(t('validEmpty')),
            length: validateLength(2, 0, t('validLength_2').replace(/(\$\{1\$\})/, 2)),
            letter: letterValidateSpecialSigns6Languages(t('validOnlyLetter')),
        }
        validations[TELEPHONE] = {
            empty: noEmptyValidate(t('validEmpty')),
            length: validateLength(6),
            number: phoneSpaceValidate(t('validNumber')),
        }
        validations[ADDRESS] = {
            empty: noEmptyValidate(t('validEmpty')),
            length: validateLength(3, null, t('validLength_2').replace(/(\$\{1\$\})/, 3)),
            letter: streetValidate6Languages(t('validValueIncorrect')),
        }
        validations[HS_CODE] = {
            empty: noEmptyValidate(t('validEmpty')),
            number: numberValidate(t('validNumber')),
            length: validateLength(6, 12, t('validLengthHsCode')),
        }
        validations[SKU] = {
            // empty: noEmptyValidate(t('validEmpty')),
            // number: numberValidate(t('validNumber')),
            // length: validateLength(4),
        }
        validations[TAX_ID_NUMBER] = {
            number: numberValidate(t('validNumber'))
        }
        validations[PASSPORT_NUMBER] = {
            number: numberValidate(t('validNumber')),
        }
        validations[PASSPORT] = {
            letter: letterValidate2(t('validOnlyLetter')),
        }
        validations[EMAIL] = {
            email: emailValidate(t('validEmail')),
            emailCharacters: emailSpecialCharactersValidate(t('validEmail')),
        }
        validations['insurance'] = {
            bool: boolValidate()
        }
        if(page === 'waiting') validations[TRACK_NUMBER] = {
            empty: noEmptyValidate(),
            number: letterNumberHyphenValidate(t('validValueChinaTrack')),
            length: validateLength('5'),
        }
    }

    const formRef = React.createRef();

    if (data) {
        data[PASSPORT_NUMBER] = parsePassport(data[PASSPORT_NUMBER])
    }

    const [errorToCountry, setErrorToCountry] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorSensitiveGoods, setErrorSensitiveGoods] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorSendBy, setErrorSendBy] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorServiceType, setErrorServiceType] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorCity, setErrorCity] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorRegion, setErrorRegion] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorZip, setErrorZip] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorAddress, setErrorAddress] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorCommodity, setErrorCommodity] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorQty, setErrorQty] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorPrice, setErrorPrice] = useState({...DEFAULT_ERROR_OBJECT});
    const [warnPrice, setWarnPrice] = useState(false);
    const [iAgreeWarnPrice, setIAgreeWarnPrice] = useState(true);
    const [errorWeight, setErrorWeight] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorIoss, setErrorIoss] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorReceiver, setErrorReceiver] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorTelephone, setErrorTelephone] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorHsCode, setErrorHsCode] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorSKU, setErrorSKU] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorEmail, setErrorEmail] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorPassportNumber, setErrorPassportNumber] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorPassport, setErrorPassport] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorTaxId, setErrorTaxId] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorInsurance, setErrorInsurance] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorTracknum, setErrorTracknum] = useState({...DEFAULT_ERROR_OBJECT});

    const [commodity, setCommodity] = useState(getFieldFromData(data, COMMODITY, ''));
    const [taxId, setTaxId] = useState(data && Array.isArray(data[TAX_ID_NUMBER]) && !data[TAX_ID_NUMBER].length ? '' : getFieldFromData(data, TAX_ID_NUMBER, ''));
    const [address, setAddress] = useState(getFieldFromData(data, ADDRESS, '').toString().trim());
    const [city, setCity] = useState(getFieldFromData(data, CITY, '').toString().trim());
    const [comment, setComment] = useState(getFieldFromData(data, COMENT, '').toString().trim());
    const [email, setEmail] = useState(getFieldFromData(data, EMAIL, '').toString().trim());
    const [hsCode, setHsCode] = useState(getFieldFromData(data, HS_CODE, '').toString().trim());
    const [checkingHSCode, setСheckingHSCode] = useState(false);
    const [hsCodeWithoutDuty, setHSCodeWithoutDuty] = useState(false);
    const [passport, setPassport] = useState(getFieldFromData(data, PASSPORT_NUMBER, [''])[0] ?? '');
    const [passportNumber, setPassportNumber] = useState(getFieldFromData(data, PASSPORT_NUMBER, ['', ''])[1] ?? '');
    const [qty, setQty] = useState(getFieldFromData(data, QTY, ''));
    const [receiver, setReceiver] = useState(getFieldFromData(data, RECEIVER, '').toString().trim());
    const [phone, setPhone] = useState(getFieldFromData(data, TELEPHONE, '').toString().trim());

    const [filterReceivers, setFilterReceivers] = useState(receivers);
    const [openReceiver, setOpenReceiver] = useState(false);
    const [openTel, setOpenTel] = useState(false);
    const [receiverId, setReceiverId] = useState(null);
    const [receiverIdSource, setReceiverIdSource] = useState(null);

    const [countryList, setCountryList] = useState([defCountry].concat(countryListRedux));

    const [region, setRegion] = useState(getFieldFromData(data, REGION, '').toString().trim());
    const [sku, setSku] = useState(getFieldFromData(data, SKU, '').toString().trim());
    const [to, setTo] = useState(getCountryObj([defCountry].concat(countryListRedux), data));
    const [serviceType, setServiceType] = useState(data && (SERVICE_TYPE in data)
        ? defServiceTypes.find(el => el.value == data[SERVICE_TYPE])
        : {value: '1', text: ''}); // PUDO
    const [associatedBarcodes, setAssociatedBarcodes] = useState(getFieldFromData(data, ASSOCIATED_BARCODES, ''));
    const [url, setUrl] = useState(getFieldFromData(data, URL, '').toString().trim());
    const [price, setPrice] = useState(getFieldFromData(data, PRICE, '').toString().replaceAll(/,+/g, '.'));
    const [weight, setWeight] = useState(getFieldFromData(data, WEIGHT, '').toString().replaceAll(/^[.,]+|[.,]+$/g, '').replaceAll(/,+/g, '.'));
    const [zip, setZip] = useState(getFieldFromData(data, ZIP, ''));
    const [checkingZip, setCheckingZip] = useState(false);
    const [goodsType, setGoodsType] = useState('');
    const [goodsTypeByCommodity, setGoodsTypeByCommodity] = useState(0);
    const [recheckGoodsTypeByCommodity, setRecheckGoodsTypeByCommodity] = useState(false); // для перевірки для зміни goodsType при редагуванні
    const [checkingGoodsTypeByCommodity, setСheckingGoodsTypeByCommodity] = useState(false); // чекаємо перевірки вище
    const [sendBy, setSendBy] = useState('');
    const [iossNumber, setIossNumber] = useState(getFieldFromData(data, IOSS_NUMBER, ''));
    const [insurance, setInsurance] = useState(!data ? false : data['Insurance'] === "false" ? false : data['Insurance'] === "true" ? true : data['Insurance'] ? data['Insurance'] : false)
    const [barcode, setBarcode] = useState(getFieldFromData(data, CODE, ''));
    const [sentdate, setSentdate] = useState(getFieldFromData(data, DATE, ''));
    const [tracker, setTracker] = useState(getFieldFromData(data, TRACKER, ''));
    const [wh, setWH] = useState(getFieldFromData(data, WAREHOUSE, ''));
    const [tracknum, setTracknum] = useState(getFieldFromData(data, TRACK_NUMBER, ''));
    const [cnts, setCnts] = useState(getFieldFromData(data, CNTS, ''));

    const [refThese, setRefThese] = useState({})

    const [europeCountry, setEuropeCountry] = useState(europeCountry_);

    const [describeStopWords, setDescribeStopWords] = useState([]);
    const [excludedGoods, setExcludedGoods] = useState({});
    const [deniedZips, setDeniedZips] = useState({});
    const [useIOSS, setUseIOSS] = useState(true)
    const [sendByOk, setSendByOk] = useState(false)
    const [showInsurance, setShowInsurance] = useState(false)
    const [insuranceValue, setInsuranceValue] = useState(defInsurance)

    const [touched, setFieldTouched] = useState({})
    const [forbiddenCommodity, setForbiddenCommodity] = useState([])
    const [isLoadForbiddenCommodity, setIsLoadForbiddenCommodity] = useState(true)
    const [sendAnaliticsStartInput, setSendAnaliticsStartInput] = useState(false)

    function getCountryObj(cntList, dt) {
        try {
            let country = defCountry;
            if (cntList && dt && dt[COUNTRY]) {
                if (cntList.find(c => c.country_id === dt[COUNTRY])) {
                    country = cntList.find(c => c.country_id === dt[COUNTRY]);
                }
            }
            return country;
        } catch(err) {
            console.log("🚀 getCountryObj err:", err);
        }
    }

    const [debounceGetGoodTypeByCommodity] = useMemo(() => {
        const [getGoodTypeByCommodityDebounce] = debounce(getGoodTypeByCommodity, 350);
        return [getGoodTypeByCommodityDebounce];
    }, [])

    const [debounceCheck, teardownCheck] = useMemo(() => {
        const [checkDebounce, checkTeardown] = debounce(({fn, args}) => fn(...args), 2000);
        return [checkDebounce, checkTeardown];
    }, [])

    savedIOSS = useMemo(() => {
        const savedIOSS: boolean = data?.useMeestIOSS === 'true';

        if (savedIOSS) {
            setIossNumber('');
        }

        return savedIOSS;
    }, [data])

    const countryISO = useMemo(() => {
        return (to && to.country_id) ? to.country_id : ''
    }, [to])

    const [debounceZip] = useMemo(() => {
        const [zipDebounce] = debounce((zipCurrent) => makePostRequest('/api/misc/checkParcelData', {
                parcels_data: [
                    {
                        id: Math.floor(Math.random() * 1000),
                        lang: lang === 'en' ? 'en' : 'zh',
                        country: countryISO,
                        Consignee_name: "",
                        Consignees_phone: "",
                        zip: zipCurrent,
                    }
                ]
            }, {}).then((res) => {
                const firstEl = res?.data?.response[0] || {};
                return firstEl;
            })
            .catch((err) => {
                console.log('checkParcelData catch: ', err);
                return {
                    check_result: false,
                    msg: "checkParcelData catch err"
                }
            }), 350);
        return [zipDebounce];
    }, [countryISO])

    const [debounceHSCode] = useMemo(() => {
        const [HSCodeDebounce] = debounce((HSCodeCurrent) => makePostRequest('/api/misc/checkParcelData', {
                parcels_data: [
                    {
                        id: Math.floor(Math.random() * 1000),
                        lang: lang === 'en' ? 'en' : 'zh',
                        country: countryISO,
                        Consignee_name: "",
                        Consignees_phone: "",
                        zip: "",
                        HS_Code: +HSCodeCurrent,
                    }
                ]
            }, {}).then((res) => {
                const firstEl = res?.data?.response[0] || {};
                return firstEl;
            })
            .catch((err) => {
                console.log('checkParcelData catch: ', err);
                return {
                    check_result: false,
                    msg: "checkParcelData catch err"
                }
            }), 350);
        return [HSCodeDebounce];
    }, [countryISO])

    const [debouncePrice] = useMemo(() => {
        const [priceDebounce] = debounce(({price_, qty_, goodsType_, commodity_}) => makePostRequest('/api/misc/checkParcelData', {
                parcels_data: [
                    {
                        id: Math.floor(Math.random() * 1000),
                        lang: lang === 'en' ? 'en' : 'zh',
                        country: countryISO,
                        Consignee_name: "",
                        Consignees_phone: "",
                        zip: "",
                        price: +price_,
                        quantity: +qty_,
                        type_of_danger: goodsType_,
                        product_name: commodity_,
                    }
                ]
            }, {}).then((res) => {
                const firstEl = res?.data?.response[0] || {};
                return firstEl;
            })
            .catch((err) => {
                console.log('checkParcelData catch: ', err);
                return {
                    check_result: false,
                    msg: "checkParcelData catch err"
                }
            }), 350);
        return [priceDebounce];
    }, [countryISO])

    const countryWithServiceType = useMemo(() => to.pudo === '1' || to.to_door === '1', [to])

    const disabledTypeOfDelivery = useMemo(() => !(to.pudo === '1' && to.to_door === '1'), [to]);

    const excludedHsCode = useMemo(() => {
        if (countryISO) {
            const list = excludedGoods[countryISO] ?? null;
            return list ? list.map(value => Number(value.hs_code)) : []
        }
        return [];
    }, [excludedGoods, to])

    const excludedZips = useMemo(() => {
        return deniedZips;
    }, [deniedZips])

    const excludedCommodity = useMemo(() => {
        let data = [];
        if (countryISO) {
            const list = excludedGoods[countryISO] ?? null;
            if (list) {
                const nameRu = list.map(value => value.descriptionEn)
                const nameEn = list.map(value => value.descriptionRu)
                const nameUa = list.map(value => value.descriptionUA)
                data = [].concat(nameRu, nameEn, nameUa).filter(value => value !== null)
            }
        }
        return data;
    }, [excludedGoods, to])

    const isDisabledDestCountry = useMemo(() => !!disableFields?.destinationCountry , [disableFields])

    function setReceiverData(recId, recIdSource) {
        if((recId !== receiverId && receiverId) && (recIdSource !== receiverIdSource && receiverIdSource)) {
            dispatch(setModalForm2({
                title: t('ConfirmRecipientChange'),
                form: <div className='px-5'>
                    {t('RecipientChangeQuestion')}
                    <br/>
                    {t('RecipientChangeUpdateInformation')}
                    <ModalButtons buttons={{
                        save: {
                            text: t('Confirm'),
                            variant: 'primary',
                            action: () => {
                                setReceived(recId, recIdSource);
                                hideModalForm2(dispatch)()
                            }
                        },
                        cancel: {
                            text: t('cancel'),
                            variant: 'outline-primary',
                            action: () => {
                                hideModalForm2(dispatch)()
                            }
                        }
                    }}/>
                </div>,
            }))
        } else {
            setReceived(recId, recIdSource);
        }
    }

    function setReceived(rcId, recIdSrc) {
        if(rcId && receivers && receivers.length) {
            const {
                ID,
                address,
                city,
                email,
                passportNumber,
                passportSeries,
                reciver,
                region,
                taxIdNumber,
                tel,
                zip
            } = receivers.find( (receiver) => receiver.ID === rcId ) || {};
            checkValidate(reciver, Object.values(validations[RECEIVER]), setErrorReceiver);
            setReceiver(reciver);
            checkValidate(tel, Object.values(validations[TELEPHONE]), setErrorTelephone);
            setPhone(tel);
            checkValidate(region, Object.values(validations[REGION]), setErrorRegion);
            setRegion(region);
            checkValidate(city, Object.values(validations[CITY]), setErrorCity);
            setCity(city);
            const zipErrObj = checkValidate(zip, Object.values(validations[ZIP]), setErrorZip);
            setZip(zip);
            checkZip(zip, zipErrObj)
            checkValidate(address, Object.values(validations[ADDRESS]), setErrorAddress);
            setAddress(address);
            checkValidate(email, Object.values(validations[EMAIL]), setErrorEmail);
            setEmail(email);
            checkValidate(passportSeries, Object.values(validations[PASSPORT]), setErrorPassport);
            setPassport(passportSeries);
            checkValidate(passportNumber, Object.values(validations[PASSPORT_NUMBER]), setErrorPassportNumber);
            setPassportNumber(passportNumber);
            checkValidate(taxIdNumber, Object.values(validations[TAX_ID_NUMBER]), setErrorTaxId);
            setTaxId(taxIdNumber);
            setReceiverId(null);
        }
        setReceiverId(rcId);
        setReceiverIdSource(recIdSrc);
    }

    useEffect(() => {
        setFilterReceivers(receivers);
    }, [receivers])

    const checking_country_weight_rules = useMemo(() => {
        return rules?.find( rule => rule.name_event === 'check_country_weight_err') ?
            rules.filter( rule => rule.name_event === 'check_country_weight_err')
            : [];
    }, [rules])

    useEffect(() => {
        delete validations[COMMODITY].excludeWord
        delete validations[HS_CODE].excludeWord
        delete validations[ZIP].excludedZips

        const countryISO = to.country_id ?? '';

        setErrorCommodity({...DEFAULT_ERROR_OBJECT})
        setErrorHsCode({...DEFAULT_ERROR_OBJECT})
        // const inEu = to && europeCountry.concat('MA').includes(to.country_id.toUpperCase());
        if (excludedHsCode.length && !validations[HS_CODE]?.excludeWord) {
            validations[HS_CODE].excludeWord = excludedValidate(excludedHsCode, 0, 6, t('excludedHsCode'))
        }
        if (excludedCommodity.length && !validations[COMMODITY]?.excludeWord) {
            validations[COMMODITY].excludeWord = excludedValidate(excludedCommodity, 0, 0, t('excludedCommodity'))
        }
        if ((excludedZips[countryISO] ?? false) && !validations[ZIP]?.excludedZips) {
            validations[ZIP].excludedZips = excludedValidate(excludedZips[countryISO].zips, 0, 0, t('deniedPostalCode'))
        }

        if (commodity && typeof commodity === 'string' && commodity.length) {
            debounceCheck({fn: checkHandleCommodity, args: [commodity, Object.values(validations[COMMODITY]), setErrorCommodity]})
        }
        if (hsCode && typeof hsCode === 'string' && hsCode.length) {
            debounceCheck({fn: checkHandleHsCode, args: [hsCode, Object.values(validations[HS_CODE]), setErrorHsCode]})
        }
        if (zip && (typeof zip === 'string' || typeof zip === 'number') && zip.toString().length) {
            checkValidate(zip.toString(), Object.values(validations[ZIP]), setErrorZip)
        }
        if (countryISO.toUpperCase() === 'UA' && !validations[RECEIVER].words) {
            validations[RECEIVER].words = wordCountWithLetterCount(2, 2, t('validLengthWords').replace(/(\$\{1\$\})/, 2), t('validLength_2').replace(/(\$\{1\$\})/, 2))
        } else if(countryISO.toUpperCase() !== 'UA') {
            delete validations[RECEIVER].words
        }
    }, [to, excludedGoods, commodity, hsCode])

    useEffect(() => {
        if(to) {
            let checkCurp = false;
            if(to.country_id === 'MX' && sendBy.value === 'AIR') {
                if(!validations[TAX_ID_NUMBER].curp || validations[TAX_ID_NUMBER].number) {
                    delete validations[TAX_ID_NUMBER].number
                    validations[TAX_ID_NUMBER] = {
                        ...validations[TAX_ID_NUMBER],
                        curp: curpValidation(t('validValueCurp')),
                    }
                    checkCurp = true;
                }
            } else {
                if(validations[TAX_ID_NUMBER].curp || !validations[TAX_ID_NUMBER].number) {
                    delete validations[TAX_ID_NUMBER].curp
                    validations[TAX_ID_NUMBER] = {
                        ...validations[TAX_ID_NUMBER],
                        number: numberValidate(t('validNumber'))
                    }
                    checkCurp = true;
                }
            }    
            if(checkCurp) {
                checkValidate(taxId, Object.values(validations[TAX_ID_NUMBER]), setErrorTaxId)
                return;
            }
            const cv = countryList.find(c => c.country_id === to.country_id && sendBy.value === c.delivery_type); // cv-countryValidation
            if(cv) {
                if(+cv.passportSeriesLenght) {
                    validations[PASSPORT] = {
                        ...validations[PASSPORT],
                        empty: noEmptyValidate(t('validEmpty')),
                        length: validateLength(+cv.passportSeriesLenght, +cv.passportSeriesLenght, t('validPassportSeries1$').replace(/(\$\{1\$\})/, +cv.passportSeriesLenght))
                    }
                } else {
                    delete validations[PASSPORT].empty
                    delete validations[PASSPORT].length
                }
                if(+cv.passportNumberLenght) {
                    validations[PASSPORT_NUMBER] = {
                        ...validations[PASSPORT_NUMBER],
                        empty: noEmptyValidate(t('validEmpty')),
                        length: validateLength(+cv.passportNumberLenght, +cv.passportNumberLenght, t('validPassportNumber1$').replace(/(\$\{1\$\})/, +cv.passportNumberLenght)),
                    }
                } else {
                    delete validations[PASSPORT_NUMBER].empty
                    delete validations[PASSPORT_NUMBER].length
                }
                if(+cv.taxNumberLenght) {
                    validations[TAX_ID_NUMBER] = {
                        ...validations[TAX_ID_NUMBER],
                        empty: noEmptyValidate(t('validEmpty')),
                        length: validateLength(+cv.taxNumberLenght, +cv.taxNumberLenght)
                    }
                } else {
                    delete validations[TAX_ID_NUMBER].empty
                    delete validations[TAX_ID_NUMBER].length
                }    
                checkValidate(passport, Object.values(validations[PASSPORT]), setErrorPassport)
                checkValidate(passportNumber, Object.values(validations[PASSPORT_NUMBER]), setErrorPassportNumber)
                checkValidate(taxId, Object.values(validations[TAX_ID_NUMBER]), setErrorTaxId)
                return;
            }
        }
        delete validations[PASSPORT].empty
        delete validations[PASSPORT].length
        delete validations[PASSPORT_NUMBER].empty
        delete validations[PASSPORT_NUMBER].length
        delete validations[TAX_ID_NUMBER].empty
        delete validations[TAX_ID_NUMBER].length

        if (errorPassport.isError) {
            checkValidate(passport, Object.values(validations[PASSPORT]), setErrorPassport)
        }
        if (errorPassportNumber.isError) {
            checkValidate(passportNumber, Object.values(validations[PASSPORT_NUMBER]), setErrorPassportNumber)
        }
        if (errorTaxId.isError) {
            checkValidate(taxId, Object.values(validations[TAX_ID_NUMBER]), setErrorTaxId)
        }
    }, [to, sendBy])

    useEffect(() => {
        setDescribeStopWords([]);
        getDangerousGoods()
            .then(({data}) => {
                setDescribeStopWords(data.map(v => v.toString().toUpperCase()))
                getExcludedGoods()
                    .then(({data}) => {
                        setExcludedGoods(data)
                    })
            })
            .catch((response) => {
                viewAlert(dispatch, response)
            })
        getDeniedZip()
            .then(({data}) => {
                setDeniedZips(data)
            })
            .catch(console.error)
        getForbiddenCommodity()
            .then(({data}) => setForbiddenCommodity(data?.response ?? []))
            .finally(() => setIsLoadForbiddenCommodity(false))

        if (isEdit) {
            if (data[SEND_BY] ?? '')
                setSendBy({value: data[SEND_BY]})
            if (data[SENSITIVE_GOODS] ?? '')
                setGoodsType({value: data[SENSITIVE_GOODS]})
        }
        if(!isEdit && getListParcels) {
            makeGetRequest("/api/misc/kk_analytics", {
                action: 'create_parcel_form_open',
                a_data: '{}'
            });
        }
        return () => {
            const urlParams = new URLSearchParams(window.location.search);
            const actionParam = urlParams.get('action');
            if(actionParam === 'create-new-parcel') {
                navigate && navigate("/new-parcels", { replace: true });
            }
        }
    }, [])

    useEffect(() => {
        const toInsuranceCountry = insuranceCountries.find((insuranceCountry) => insuranceCountry.country_id === to.country_id);
        const ok = toInsuranceCountry && to && checkOnNum(price) && (+((price+'').replace(',', '.')) > 0) && sendBy && goodsType && commodity;
        if (ok && sendByOk) {
            !showInsurance && setShowInsurance(true);
            if (insurance) {

                const req = {
                    "country": to.country_id,
                    "value": +price.replace(',', '.'),
                    "ship": sendBy.value,
                    "sensitive_goods": goodsType.value
                };
                getCostInsurance(req).then((res) => {
                    setInsuranceValue(res.data || {})
                })
            }
        } else if (ok) {
            const country = countryList.find(c => c.country_id === to.country_id && c.delivery_type === sendBy.value);
            checkGoodsSend(country, commodity);
        } else {
            showInsurance && setShowInsurance(false);
        }
    }, [insurance, to, price, sendBy, sendByOk, goodsType, commodity])

    useEffect(() => {
        warningForCountryWeight(weight);
    }, [countryISO, sendBy, sendByOk, goodsType, goodsType])

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        if(loadSpinner) return;

        const form = e.currentTarget;

        const requestData = serializeFormToObject(form);

        requestData[TELEPHONE] = requestData[TELEPHONE].toString().replaceAll(/\D/ig, '').trim();
        requestData[QTY] = requestData[QTY].toString().replaceAll(/^[.,]+|[.,]+$/g, '');
        requestData[WEIGHT] = requestData[WEIGHT].toString().replaceAll(/^[.,]+|[.,]+$/g, '').replaceAll(/,+/g, '.');
        requestData[PRICE] = requestData[PRICE].toString().replaceAll(/^[.,]+|[.,]+$/g, '');
        requestData[PASSPORT_NUMBER] = passportNumber;
        requestData[PASSPORT] = passport.toString().toUpperCase();
        requestData['ioss_switch'] = !useIOSS;
        requestData['insurance'] = insurance;
        requestData[REGION] = requestData[REGION].trim();
        requestData[CITY] = requestData[CITY].trim();
        requestData[ADDRESS] = requestData[ADDRESS].trim();
        requestData[RECEIVER] = requestData[RECEIVER].trim();
        // debugger;

        requestData.uid = data?.uid;

        const withCheckWeightAndPrice = to && sendBy && sendBy.value && goodsType && goodsType.value;
        const sendByParams = countries[to.country_id] && sendBy ? countries[to.country_id][sendBy?.value] : {};
        const errors = [
            checkValidate(requestData[SENSITIVE_GOODS], Object.values(validations[SENSITIVE_GOODS]), setErrorSensitiveGoods),
            checkValidate(requestData[SEND_BY], Object.values(validations[SEND_BY]), setErrorSendBy),
            checkValidate(requestData[QTY], Object.values(validations[QTY]), setErrorQty),
            checkValidate(requestData[WEIGHT], Object.values(validations[WEIGHT])
                .concat(withCheckWeightAndPrice
                    ? [minMaxValue(
                        sendByParams[goodsType.value === '0' ? 'JN' : goodsType.value === '1'? 'SN' : 'DG']?.weightMin,
                        sendByParams[goodsType.value === '0' ? 'JN' : goodsType.value === '1'? 'SN' : 'DG']?.weightMax,
                        t('validMinWeight').replace(/(\$\{1\$})/, sendByParams[goodsType.value === '0' ? 'JN' : goodsType.value === '1'? 'SN' : 'DG']?.weightMin) + ' KG',
                        t('validMaxValue2').replace(/(\$\{1\$})/, sendByParams[goodsType.value === '0' ? 'JN' : goodsType.value === '1'? 'SN' : 'DG']?.weightMax) + ' KG'
                    )]
                    : []), setErrorWeight),
            checkValidate(requestData[PRICE], Object.values(validations[PRICE])
                .concat(withCheckWeightAndPrice && !hsCodeWithoutDuty //goodsType  SN - 1, DG - 2, JN - 0
                    ? [minMaxValue(
                        sendByParams[goodsType.value === '0' ? 'JN' : goodsType.value === '1'? 'SN' : 'DG']?.priceMin,
                        sendByParams[goodsType.value === '0' ? 'JN' : goodsType.value === '1'? 'SN' : 'DG']?.priceMax,
                        t('validMinValue').replace(/(\$\{1\$})/, sendByParams[goodsType.value === '0' ? 'JN' : goodsType.value === '1'? 'SN' : 'DG']?.priceMin) + ' USD',
                        t('validMaxValue').replace(/(\$\{1\$})/, sendByParams[goodsType.value === '0' ? 'JN' : goodsType.value === '1'? 'SN' : 'DG']?.priceMax) + ' USD'
                    )]
                    : []), setErrorPrice),
            checkValidate(requestData[HS_CODE], Object.values(validations[HS_CODE]), setErrorHsCode),
            checkValidate(requestData[COMMODITY], Object.values(validations[COMMODITY]), setErrorCommodity),
            checkValidate(requestData[ZIP], Object.values(validations[ZIP]), setErrorZip),
            checkValidate(requestData[REGION], Object.values({empty: validations[REGION].empty}), setErrorRegion),
            checkValidate(requestData[CITY], Object.values(validations[CITY]), setErrorCity),
            checkValidate(requestData[ADDRESS], Object.values(validations[ADDRESS]), setErrorAddress),
            checkValidate(requestData[RECEIVER], Object.values(validations[RECEIVER]), setErrorReceiver),
            checkValidate(requestData[TELEPHONE], Object.values(validations[TELEPHONE]), setErrorTelephone),
            checkValidate(requestData[EMAIL], Object.values(validations[EMAIL]), setErrorEmail),
            checkValidate(requestData[PASSPORT], Object.values(validations[PASSPORT]), setErrorPassport),
            checkValidate(requestData[PASSPORT_NUMBER], Object.values(validations[PASSPORT_NUMBER]), setErrorPassportNumber),
            checkValidate(requestData[TAX_ID_NUMBER], Object.values(validations[TAX_ID_NUMBER]), setErrorTaxId),
            checkValidate(requestData['insurance'], Object.values(validations['insurance']), setErrorInsurance),
        ];
        if(page === 'waiting') errors.push(checkValidate(requestData[TRACK_NUMBER], Object.values(validations[TRACK_NUMBER]), setErrorTracknum));
        if(!iAgreeWarnPrice && to) {
            const warnPriceError = {
                isError: true,
                message: t('exceededDutyFreeAmount').replace(/({\$1})/ig, sendByParams[goodsType?.value === '0' ? 'JN' : goodsType?.value === '1'? 'SN' : 'DG']?.customs_cost)
            };
            errors.push(warnPriceError);
            setErrorPrice(warnPriceError);
        }

        if (europeCountry.includes(requestData[TO_COUNTRY]) && !useIOSS)
            errors.push(checkValidate(requestData[IOSS_NUMBER] ?? '', Object.values(validations[IOSS_NUMBER]), setErrorIoss));

        const errorCommodity = checkForbiddenCommodity(requestData[COMMODITY])

        const isError = errors.find(value => value.isError) || errorCommodity;

        if (!isError && !checkingGoodsTypeByCommodity && !checkingZip && !checkingHSCode) {
            save(requestData);
        }
    }

    function save(requestData) {
        dispatch(setLoadSpinner(true))
        createUpdateNewParcel(requestData)
            .then((res) => {
                if(page !== 'waiting') return res;
                if(res.status === 200) {
                    return getParcelInfo({uid: requestData.uid})
                        .then(({data}) => {
                            const requestDataSendToWarehouse = {
                                parcels: [data.item],
                            };
                            requestDataSendToWarehouse[TRACK] = [tracknum];
                            requestDataSendToWarehouse[CNTS] = cnts;
                            requestDataSendToWarehouse[WAREHOUSE_FULL] = wh;
                            return sendToWarehouse(requestDataSendToWarehouse);
                        })
                } else {
                    throw new Error({
                        title: t('error'),
                        text: res.statusText,
                        type: 'danger'
                    })
                }
            })
            .then((response) => {
                hideForm();
                // viewAlert(dispatch, response, {text: t('success').toLowerCase()})
                dispatch(setModalForm({
                    title: '',
                    form: <SuccessCreate/>,
                    fullScreen: true,
                    hideOut: false,
                    withoutClose: true,
                }));
                if(!isEdit && getListParcels) {
                    makeGetRequest("/api/misc/kk_analytics", {
                        action: 'create_parcel_form_success',
                        a_data: '{}'
                    });
                }
            })
            .then(() => {
                if (getListParcels) {
                    getListParcels()
                        .then(response => {
                            dispatch(setList(response.data, t('noStatus')))
                        })
                        .catch(({response}) => {
                            viewAlert(dispatch, response)
                        })
                        .finally(() => dispatch(setLoadSpinner(false)));
                }
                dispatch(setSelectedList([]))
                dispatch(setLoadSpinner(false));
                afterCreate && afterCreate();
            })
            .catch(({response}) => {
                dispatch(setLoadSpinner(false));
                viewAlert(dispatch, response, {
                    title: t('error'),
                    type: 'danger'
                })
            })
    }

    function handleServiceType(country) {

        if (!country) return;

        if (country.pudo === '1' && country.to_door === '0')
            setServiceType(defServiceTypes.find(v => v.value === '1')) // PUDO
        else if (country.pudo === '0' && country.to_door === '1') {
            setServiceType(defServiceTypes.find(v => v.value === '0')) // toDoor
        }
    }

    function checkHandleQty(value, validation, setErrorQty) {
        const errObj = checkValidate(value, validation, setErrorQty);
        checkHSCode(hsCode, errObj, price, true, value, goodsType.value, commodity);
    }

    function checkHandleWeight(value, validation, setErrorWeight) {
        const withCheckWeightAndPrice = to && sendBy && sendBy.value && goodsType && goodsType.value;
        const sendByParams = countries[to.country_id] && sendBy ? countries[to.country_id][sendBy?.value] : {};
        const validError: any = checkValidate(value, validation
            .concat(withCheckWeightAndPrice ?
                [minMaxValue(
                    Number(sendByParams[goodsType.value === '0' ? 'JN' : goodsType.value === '1'? 'SN' : 'DG']?.weightMin),
                    Number(sendByParams[goodsType.value === '0' ? 'JN' : goodsType.value === '1'? 'SN' : 'DG']?.weightMax),
                    t('validMinWeight').replace(/(\$\{1\$})/, Number(sendByParams[goodsType.value === '0' ? 'JN' : goodsType.value === '1'? 'SN' : 'DG']?.weightMin)) + ' KG',
                    t('validMaxValue2').replace(/(\$\{1\$})/, Number(sendByParams[goodsType.value === '0' ? 'JN' : goodsType.value === '1'? 'SN' : 'DG']?.weightMax)) + ' KG'
                )]
                : []), setErrorWeight)
        if(!validError.isError) {
            warningForCountryWeight(value);
        }
    }

    function checkHandlePrice(value, validation, inputRef, setErrorPrice) {
        checkHSCode(hsCode, DEFAULT_ERROR_OBJECT, value, !!inputRef, qty, goodsType.value, commodity)
    }

    function checkHandleHsCode(value, validation, setErrorHsCode) {
        const errObj = checkValidate(value, validation, setErrorHsCode)
        checkHSCode(value, errObj, price, true, qty, goodsType, commodity)
    }

    function checkHandleSKU(value, validation, setErrorSKU) {
        checkValidate(value, validation, setErrorSKU);
    }

    function checkHandleCommodity(value, validation, setErrorCommodity) {
        const errObj = checkValidate(value, validation, setErrorCommodity)
        checkForbiddenCommodity(value)
        checkCommodity(value);
        checkHSCode(hsCode, errObj, price, true, qty, goodsType.value, value.trim())
    }

    function checkHandleIoss(value, validation, setErrorIoss) {
        checkValidate(value, validation, setErrorIoss)
    }

    function checkHandleTracknum(value, validation, setErrorTracknum) {
        checkValidate(value, validation, setErrorTracknum)
    }

    function checkHandleReceiver(value, validation, setErrorReceiver) {
        checkValidate(value, validation, setErrorReceiver)
        filterReceiver(value, RECEIVER)
    }

    function checkHandleTelephone(value, validation, setErrorTelephone) {
        const errObj = checkValidate(value, validation, setErrorTelephone)
        if(!errObj.isError && value && countryISO.length) {
            checkPhone({phone_: value, countryISO_: countryISO})
        }
        filterReceiver(value, TELEPHONE)
    }

    function checkHandleRegion(value, validation, setErrorRegion) {
        checkValidate(value, validation, setErrorRegion)
    }

    function checkHandleCity(value, validation, setErrorCity) {
        checkValidate(value, validation, setErrorCity)
    }

    function checkHandleZip(value, validation, setErrorZip) {
        const errObj = checkValidate(value, validation, setErrorZip)
        checkZip(value, errObj)
    }

    function checkHandleAddress(value, validation, setErrorAddress) {
        checkValidate(value, validation, setErrorAddress)
    }

    function checkHandleEmail(value, validation, setErrorEmail) {
        checkValidate(value, validation, setErrorEmail)
    }

    function checkHandlePassport(value, validation, setErrorPassport) {
        checkValidate(value, validation, setErrorPassport)
    }

    function checkHandlePassportNumber(value, validation, setErrorPassportNumber) {
        checkValidate(value, validation, setErrorPassportNumber)
    }

    function checkHandleTaxId(value, validation, setErrorTaxId) {
        checkValidate(value, validation, setErrorTaxId)
    }

    function handleValues(value, name, inputRef, eventType) {

        switch (name) {
            case TO_COUNTRY:
                checkValidate(value.country_id, Object.values(validations[name]), setErrorToCountry)
                setTo(value);
                handleServiceType(value)
                break;
            case SENSITIVE_GOODS: {
                const errObj = checkValidate(value.value, Object.values(validations[name]), setErrorSensitiveGoods)
                checkGoodType(value);
                checkHSCode(hsCode, errObj, price, true, qty, value.value, commodity)
                break;
            }
            case SEND_BY:
                checkValidate(value.value, Object.values(validations[name]), setErrorSendBy)
                setSendBy(value)
                if (value.value && goodsType && goodsType.value && commodity.length)
                    checkSendBy(value)
                break;
            case SERVICE_TYPE:
                // checkValidate(value.value, Object.values(validations[name]), setErrorServiceType)
                setServiceType(value)
                checkZip(zip, {}, false, value)
                break;
            case QTY: {
                setQty(value)
                if(eventType === "change") {
                    debounceCheck({fn: checkHandleQty, args: [value, Object.values(validations[name]), setErrorQty]})
                } else checkHandleQty(value, Object.values(validations[name]), setErrorQty)
                break;
            }
            case WEIGHT: {
                value = value.toString().replaceAll(/^[.,]+|[.,]+$/g, '').replaceAll(/,+/g, '.');
                setWeight(value);
                if(eventType === "change") {
                    debounceCheck({fn: checkHandleWeight, args: [value, Object.values(validations[name]), setErrorWeight]})
                } else checkHandleWeight(value, Object.values(validations[name]), setErrorWeight)
                break;
            }
            case PRICE: {
                value = value.toString().replaceAll(/,+/g, '.');
                setPrice(value)
                if(eventType === "change") {
                    debounceCheck({fn: checkHandlePrice, args: [value, Object.values(validations[name]), inputRef, setErrorPrice]})
                } else checkHandlePrice(value, Object.values(validations[name]), inputRef, setErrorPrice)
                break;
            }
            case HS_CODE: {
                setHsCode(value);
                if(eventType === "change") {
                    debounceCheck({fn: checkHandleHsCode, args: [value, Object.values(validations[name]), setErrorHsCode]})
                } else checkHandleHsCode(value, Object.values(validations[name]), setErrorHsCode)
                break;
            }
            case SKU:
                setSku(value)
                if(eventType === "change") {
                    debounceCheck({fn: checkHandleSKU, args: [value, Object.values(validations[name]), setErrorSKU]})
                } else checkHandleSKU(value, Object.values(validations[name]), setErrorSKU)
                break;
            case COMMODITY: {
                setCommodity(value)
                if(eventType === "change") {
                    debounceCheck({fn: checkHandleCommodity, args: [value, Object.values(validations[name]), setErrorCommodity]})
                } else checkHandleCommodity(value, Object.values(validations[name]), setErrorCommodity)
                break;
            }
            case URL:
                setUrl(value)
                break;
            case IOSS_NUMBER: {
                setIossNumber(value);
                if(eventType === "change") {
                    debounceCheck({fn: checkHandleIoss, args: [value, Object.values(validations[name]), setErrorIoss]})
                } else checkHandleIoss(value, Object.values(validations[name]), setErrorIoss)
                break;
            }
            case TRACK_NUMBER:
                setTracknum(value);
                if(eventType === "change") {
                    debounceCheck({fn: checkHandleTracknum, args: [value, Object.values(validations[name]), setErrorTracknum]})
                } else checkHandleTracknum(value, Object.values(validations[name]), setErrorTracknum)
                break;
            case RECEIVER: {
                const trimValue = value.trim();
                setReceiver(trimValue);
                if(eventType === "change") {
                    debounceCheck({fn: checkHandleReceiver, args: [trimValue, Object.values(validations[name]), setErrorReceiver]})
                } else checkHandleReceiver(trimValue, Object.values(validations[name]), setErrorReceiver)
                break;
            }
            case TELEPHONE: {
                const trimValue = value.trim();
                setPhone(trimValue);
                if(eventType === "change") {
                    debounceCheck({fn: checkHandleTelephone, args: [trimValue, Object.values(validations[name]), setErrorTelephone]});
                } else {
                    teardownCheck();
                    checkHandleTelephone(trimValue, Object.values(validations[name]), setErrorTelephone);
                }
                break;
            }
            case REGION: {
                const trimValue = value.trim();
                setRegion(trimValue);
                if(eventType === "change") {
                    debounceCheck({fn: checkHandleRegion, args: [trimValue, Object.values(validations[name]), setErrorRegion]})
                } else checkHandleRegion(trimValue, Object.values(validations[name]), setErrorRegion)
                break;
            }
            case CITY: {
                const trimValue = value.trim();
                setCity(trimValue);
                if(eventType === "change") {
                    debounceCheck({fn: checkHandleCity, args: [trimValue, Object.values(validations[name]), setErrorCity]})
                } else checkHandleCity(trimValue, Object.values(validations[name]), setErrorCity)
                break;
            }
            case ZIP: {
                setZip(value);
                if(eventType === "change") {
                    debounceCheck({fn: checkHandleZip, args: [value, Object.values(validations[name]), setErrorZip]})
                } else checkHandleZip(value, Object.values(validations[name]), setErrorZip)
                break;
            }
            case ADDRESS: {
                const trimValue = value.trim();
                setAddress(trimValue);
                if(eventType === "change") {
                    debounceCheck({fn: checkHandleAddress, args: [trimValue, Object.values(validations[name]), setErrorAddress]})
                } else checkHandleAddress(trimValue, Object.values(validations[name]), setErrorAddress)
                break;
            }
            case EMAIL:
                setEmail(value);
                if(eventType === "change") {
                    debounceCheck({fn: checkHandleEmail, args: [value, Object.values(validations[name]), setErrorEmail]})
                } else checkHandleEmail(value, Object.values(validations[name]), setErrorEmail)
                break;
            case PASSPORT:
                setPassport(value);
                if(eventType === "change") {
                    debounceCheck({fn: checkHandlePassport, args: [value, Object.values(validations[name]), setErrorPassport]})
                } else checkHandlePassport(value, Object.values(validations[name]), setErrorPassport)
                break;
            case PASSPORT_NUMBER:
                setPassportNumber(value);
                if(eventType === "change") {
                    debounceCheck({fn: checkHandlePassportNumber, args: [value, Object.values(validations[name]), setErrorPassportNumber]})
                } else checkHandlePassportNumber(value, Object.values(validations[name]), setErrorPassportNumber)
                break;
            case TAX_ID_NUMBER:
                setTaxId(value);
                if(eventType === "change") {
                    debounceCheck({fn: checkHandleTaxId, args: [value, Object.values(validations[name]), setErrorTaxId]})
                } else checkHandleTaxId(value, Object.values(validations[name]), setErrorTaxId)
                break;
            case COMENT:
                setComment(value)
                break;
            default:
                break
        }
        if(!isEdit && getListParcels && !sendAnaliticsStartInput) {
            makeGetRequest("/api/misc/kk_analytics", {
                action: 'create_parcel_form_started_filling_up',
                a_data: '{}'
            });
            setSendAnaliticsStartInput(true);
        }
    }

    function checkPrice(value, name, hs_code_without_duty = hsCodeWithoutDuty, isBlur, qty_, goodsType_, commodity_) {
        const withCheckWeightAndPrice = to && sendBy && sendBy.value && goodsType && goodsType.value;
        const sendByParams = countries[to.country_id] ? countries[to.country_id][sendBy?.value] : {};
        const validationsPrice = Object.values(validations[name])
            .concat(withCheckWeightAndPrice && !hs_code_without_duty ?
            [minMaxValue(
                sendByParams[goodsType.value === '0' ? 'JN' : goodsType.value === '1'? 'SN' : 'DG']?.priceMin,
                sendByParams[goodsType.value === '0' ? 'JN' : goodsType.value === '1'? 'SN' : 'DG']?.priceMax,
                t('validMinValue').replace(/(\$\{1\$})/, sendByParams[goodsType.value === '0' ? 'JN' : goodsType.value === '1'? 'SN' : 'DG']?.priceMin) + ' USD',
                t('validMaxValue').replace(/(\$\{1\$})/, sendByParams[goodsType.value === '0' ? 'JN' : goodsType.value === '1'? 'SN' : 'DG']?.priceMax) + ' USD'
            )]
            : []);
        const validError: any = checkValidate(value, validationsPrice, setErrorPrice);
        if(!validError.isError) {
            if(to && sendByParams && +sendByParams[goodsType?.value === '0' ? 'JN' : goodsType?.value === '1'? 'SN' : 'DG']?.customs_cost) {
                const exceedingWarn = value > +sendByParams[goodsType?.value === '0' ? 'JN' : goodsType?.value === '1'? 'SN' : 'DG']?.customs_cost;
                setWarnPrice(exceedingWarn && !hs_code_without_duty);
                if(!exceedingWarn) {
                    setErrorPrice({...DEFAULT_ERROR_OBJECT});
                    setIAgreeWarnPrice(true);
                }
            } else {
                setErrorPrice({...DEFAULT_ERROR_OBJECT});
                setIAgreeWarnPrice(true);
                setWarnPrice(false);
            }
            isBlur && checkMinPrice({price_: value, qty_: qty_, goodsType_: goodsType.value, commodity_: commodity.trim()});
        }
        setPrice(value)
    }

    function checkHSCode(value, errObj, price_, isBlur, qty_, goodsType_, commodity_) {
        if(!errObj.isError && value) {
            setСheckingHSCode(true);
            setErrorPrice({
                isError: true,
                message: t('Price_process_cheking')
            })
            debounceHSCode(value).then((firstElRes) => {
                setHSCodeWithoutDuty(firstElRes.without_duty);
                setСheckingHSCode(false);
                checkPrice(price_, PRICE, firstElRes.without_duty, isBlur, qty_, goodsType_, commodity_);
            });
        }
    }

    function checkMinPrice(args, errObj) {
        if(!errObj?.isError && args.price_ && args.qty_ && args.goodsType_ && args.commodity_) {
            setErrorPrice({
                isError: true,
                message: t('Price_process_cheking')
            })
            debouncePrice(args).then((res) => {
                if(!res.check_min_price?.result && +args.price_ < res.check_min_price?.min_price) {
                    setErrorPrice({
                        isError: false,
                        message: t('Value_has_been_changed')
                    })
                    setPrice(res.check_min_price?.min_price+'')
                } else if(res.check_min_price?.result) {
                    setErrorPrice({...DEFAULT_ERROR_OBJECT})
                }
            });
        }
    }

    function checkZip(value, errObj, withZipCheking = true, serviceTypeNew) {
        if(!errObj.isError && value) {
            setCheckingZip(true);
            debounceZip(value).then((firstElRes) => {
                const check_result = firstElRes?.zip?.check_result;
                const message = firstElRes?.zip?.msg;
                const { pudo, door} = firstElRes.type_of_delivery;
                if(!check_result && withZipCheking) {
                    setErrorZip({
                        isError: !check_result,
                        message
                    })
                }
                if(countryISO === 'UA') {
                    if(!door) {
                        if(serviceType.value === '0' || serviceTypeNew?.value === '0') {
                            setServiceType(defServiceTypes.find(v => v.value === '1'))
                            setErrorServiceType({isError: false, message: t('zip_only_PUDO')});
                        } else if(errorServiceType.message === t('zip_only_PUDO')) {
                            setErrorServiceType({...DEFAULT_ERROR_OBJECT});
                        }
                    } else if(errorServiceType.message === t('zip_only_PUDO')) {
                        setErrorServiceType({...DEFAULT_ERROR_OBJECT});
                    }
                }
                setCheckingZip(false);
            });
        }
    }

    function warningForCountryWeight(weight_value) {
        if(checking_country_weight_rules.length) {
            const check_country_weight_err = checking_country_weight_rules.find( rule => rule.rules.country === countryISO); // 'MX'
            if(check_country_weight_err) {
                const {
                    country,
                    danger_type,
                    delivery_type,
                    help_text_cn,
                    help_text_en,
                    max_weight_kg,
                    service_type,
                } = check_country_weight_err.rules;

                if( danger_type == +goodsType.value &&
                    sendBy.value && delivery_type && delivery_type.split(', ').includes(sendBy.value) && // 'AIR'
                    max_weight_kg <= weight_value &&
                    service_type == +serviceType.value
                ) {
                    setErrorWeight({
                        isError: false,
                        message: t(i18n.language === 'cn' ? help_text_cn : help_text_en)
                    });
                } else {
                    setErrorWeight({
                        isError: false,
                        message: ''
                    });
                }
            } else {
                setErrorWeight({
                    isError: false,
                    message: ''
                });
            }
        }
    }

    function filterReceiver(trimValue, field = 'reciver') {
        if(trimValue.length) {
            const filtRec = receivers.filter( (receiver) => receiver[field]?.toUpperCase().includes(trimValue.toUpperCase()));
            setFilterReceivers(filtRec);
        } else {
            setFilterReceivers(receivers);
        }
    }

    function notificationTax(cost, country) {
        if(warnPrice && cost) displayWarning({text: t('alertTextAdditionalCustomDuties').replace(/({\$1})/ig, countries[to.country_id][sendBy.value][goodsType.value === '0' ? 'JN' : goodsType.value === '1'? 'SN' : 'DG']?.customs_cost).replace(/({\$2})/ig, to.country_name_en),
            title: t('warning'),
            withoutCancel: true,
            children: <ModalButtons buttons={{
                cancel: {
                    text: t('IDontAgree'),
                    variant: 'outline-primary',
                    type: 'button',
                    action: iDontAgree,
                },
                save: {
                    text: t('IAgree'),
                    variant: 'primary',
                    type: 'button',
                    action: iAgree,
                }
            }}/>
        })
    }

    function iDontAgree(cost, country) {
        dispatch(setAlertClear(null));
        dispatch(setAlertShow(false));
        setErrorPrice({
            isError: true,
            message: t('exceededDutyFreeAmount').replace(/({\$1})/ig, countries[to.country_id][sendBy.value][goodsType.value === '0' ? 'JN' : goodsType.value === '1'? 'SN' : 'DG']?.customs_cost)
        });
        setIAgreeWarnPrice(false);
    }

    function iAgree(cost, country) {
        dispatch(setAlertClear(null));
        dispatch(setAlertShow(false));
    }

    function hideForm() {
        hideModalForm(dispatch)()
    }

    function checkGoodType(value) {
        if(!recheckGoodsTypeByCommodity && commodity) {
            setСheckingGoodsTypeByCommodity(true);
            const cmdt = commodity.trim();
            debounceGetGoodTypeByCommodity({description: cmdt}).then((res) => {
                if(res.data) {
                    resetGoodsType(res.data.Good_danger_type);
                    setGoodsTypeByCommodity(res.data.Good_danger_type);
                    setRecheckGoodsTypeByCommodity(true);
                }
                setСheckingGoodsTypeByCommodity(false);
            });
        }
        if(goodsTypeByCommodity === 0 || (goodsTypeByCommodity === 1 && value.value !== '0')) {
            setGoodsType(value);
        }
        if(goodsTypeByCommodity !== 0) {
            setErrorSensitiveGoods({isError: false, message: t('this_type_has_goods_type_Dangerous')});
        }
        if (value.value && sendBy && sendBy.value && commodity) {
            const country = countryList.find(c => c.country_id === to.country_id && c.delivery_type === sendBy.value)
            checkGoodsSend(country, commodity);
        }
    }

    function checkSendBy(send) {
        const country = countryList.find(c => c.country_id === to.country_id && c.delivery_type === send.value)
        checkGoodsSend(country, commodity);
    }

    function checkCommodity(cmdt) {
        const commodity = cmdt.trim();
        if (sendBy && sendBy.value && goodsType && goodsType.value && commodity.length) {
            const country = countryList.find(c => c.country_id === to.country_id && c.delivery_type === sendBy.value)
            checkGoodsSend(country, commodity);
        }
        if(commodity) {
            setСheckingGoodsTypeByCommodity(true);
            debounceGetGoodTypeByCommodity({description: commodity}).then((res) => {
                if(res.data) {
                    resetGoodsType(res.data?.Good_danger_type);
                    setGoodsTypeByCommodity(res.data?.Good_danger_type);
                    setRecheckGoodsTypeByCommodity(true);
                }
                setСheckingGoodsTypeByCommodity(false);
            });
        }
    }

    function checkGoodsSend(country, commodity) {
        if (country.sensitive === 'false' && (goodsType && +goodsType.value === 1)) {
            setSendBy(defSendBy)
            setSendByOk(false)
            //alert
            displayWarning({text: t('prohibited_sensitive')})
        } else if (country.danger === 'false' && (goodsType && +goodsType.value === 2)) {
            setSendBy(defSendBy)
            setSendByOk(false)
            //alert
            displayWarning({text: t('prohibited_danger')})
        } else if ((describeStopWords.includes(commodity.toUpperCase()) && country.battery === 'false')) {
            setSendBy(defSendBy)
            setGoodsType(defGoodType)
            setSendByOk(false)
            //alert
            displayWarning({
                text: t('prohibited_battery')
                    .replace(/({\$1})/ig, country.country_name.toUpperCase())
                    .replace(/({\$2})/ig, country.delivery_type)
            })
        } else {
            setSendByOk(true)
        }
    }

    function checkForbiddenCommodity(commodity: string): boolean {
        return !!forbiddenCommodity
            .map(fc => {

                const reg = new RegExp(`${!fc.SearchByEntry ? '^' : ''}(${fc.Text})${!fc.SearchByEntry ? '$' : ''}`, 'ig')

                const isError = reg.test(commodity.trim());
                if (isError) {
                    setErrorCommodity({
                        isError: true,
                        message: t('validValueForbidden')
                    })
                }
                return isError;

            })
            .find(v => v)
    }

    function resetGoodsType(good_danger_type) {
        if(good_danger_type === 2) {
            setGoodsType({value: '2', text: t('pure_buttery')});
        } else if(good_danger_type === 1 && +goodsType.value !== 2) {
            setGoodsType({value: '1', text: t('sensitive_goods')});
        }
        if(good_danger_type !== 0) {
            setErrorSensitiveGoods({isError: false, message: t('this_type_has_goods_type_Dangerous')});
        }
        // якщо {value: '0', text: t('general_goods')} то нічого не робимо
    }

    function displayWarning({text, ...data}) {
        dispatch(setAlert({
            title: t('warning'),
            text,
            type: 'warning',
            ...data
        }))
    }

    function parsePassport(number) {
        if (Array.isArray(number))
            number = number.join();
        if (number.length > 2) {
            return [number.slice(0, 2), number.slice(2)]
        }
        return ['', number]
    }

    function isEuropeCountry(to) {
        return europeCountry.includes(to?.country_id)
    }

    function handleHover(event, ref, isHover = true) {
        if (isHover)
            ReactTooltip.show(ref)
        else
            ReactTooltip.hide(ref)
    }

    function getFieldFromData(data, field, defaultValue) {
        if (data && data[field] !== undefined)
            return data[field];
        return defaultValue;
    }

    const sendByList = useMemo(() => {
        try {
            if (to) {
                return [defSendBy, ...Object.keys(to && countries[to.country_id] ? countries[to.country_id] : {}).map(delivery_type => {
                    return {value: delivery_type, text: delivery_type}
                })];
            }
            return [defSendBy]
        } catch(err) {
            console.log("🚀 sendByList err:", err);
        }
    }, [to])

    const goodTypeList = useMemo(() => {
        try {
            let list = [defGoodType];
            if (to) {
                const tmp = [...countryList.filter(c => c.country_id === to.country_id)];
                tmp.forEach(c => {
                    if (c.sensitive === 'true' && !list.find(v => v.value === '1'))
                        list.push({value: '1', text: t('sensitive_goods')})
                    if (c.danger === 'true' && !list.find(v => v.value === '2'))
                        list.push({value: '2', text: t('pure_buttery')})
                })
                //Uzbekistan goods type
                // if (to.country_id === 'UZ') {
                list.push({value: '0', text: t('general_goods')})
                // }
            }
            return list;
        } catch(err) {
            console.log("🚀 goodTypeList err:", err);
        }
    }, [to])

    useMemo(() => {
        if (isEuropeCountry(to))
            handleUseSwitch(savedIOSS)
    }, [to])

    const countrySelectList = useMemo(() => {
        try {
            // debugger;
            const list = [];
            countryList.forEach(c => {
                if (!list.find(v => v.country_id === c.country_id)) {
                    list.push(c);
                }

                if (c.selected && !data) {
                    setTo(c)
                    handleServiceType(c)
                    // handleValues(c, TO_COUNTRY)
                }
            })
            if (data && data[COUNTRY]) {
                if (countryList.find(c => c.country_id === data[COUNTRY])) {
                    const country = countryList.find(c => c.country_id === data[COUNTRY]);
                    country && setTo(country)
                } else {
                    const country = countryList.find(c => c.country_id === '');
                    country && setTo(country)
                        // handleValues(country, TO_COUNTRY)
                }
            }
            return list;
        } catch(err) {
            console.log("🚀 countrySelectList err:", err);
        }
    }, [countryList])

    function handleUseSwitch(value) {
        setUseIOSS(value);
    }

    function switchInsurance(value) {
        setInsurance(value);
    }

    function checkPhone({phone_, countryISO_}) {
        setErrorTelephone({
            isError: true,
            message: t('Phone_process_cheking')
        })
        const params = {
            parcels_data: [
                {
                    id: Math.floor(Math.random() * 1000),
                    lang: lang === 'en' ? 'en' : 'zh',
                    country: countryISO_,
                    Consignee_name: "",
                    Consignees_phone: phone_,
                }
            ]
        }
        makePostRequest('/api/misc/checkParcelData', params, {}).then((res) => {
            const firstEl = res.data.response[0];
            const check_result = firstEl.Consignees_phone.check_result;
            const message = firstEl.Consignees_phone.msg;
            setErrorTelephone({
                isError: false,
                showRed: !check_result,
                message
            })
            })
            .catch((err) => {
                console.log('checkParcelData catch: ', err)
            })
    }

    return (
        <>
            <Form ref={formRef} noValidate onSubmit={handleSubmit} className={''}>
                <table id={FORM_ID} width={'100%'}>
                    <tbody>
                    <tr>
                        <td colSpan={14}>
                            <div style={{
                                // maxWidth: '1275px',
                                maxWidth: '1400px',
                                background: '#FEED8E',
                                padding: '14px 15px',
                                fontSize: '12px',
                                fontWight: 400,
                                fontHeight: '14px',
                                color: '#000000',
                                border: '1px solid #A9981F',
                                borderRadius: '10px'
                            }}>
                                <TextForm
                                    lang={lang}
                                    countryList={countryList}
                                    europeCountry={europeCountry}
                                    refThese={refThese}
                                    setRefThese={setRefThese}
                                    handleHover={handleHover}
                                />
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={14} className={'pt-4 background-wрite'}><strong>{t('parcelDetails')}</strong></td>
                    </tr>
                    <tr>
                        <td colSpan={1} className={'width-1-14'}></td>
                        <td colSpan={1} className={'width-1-14'}></td>
                        <td colSpan={1} className={'width-1-14'}></td>
                        <td colSpan={1} className={'width-1-14'}></td>
                        <td colSpan={1} className={'width-1-14'}></td>
                        <td colSpan={1} className={'width-1-14'}></td>
                        <td colSpan={1} className={'width-1-14'}></td>
                        <td colSpan={1} className={'width-1-14'}></td>
                        <td colSpan={1} className={'width-1-14'}></td>
                        <td colSpan={1} className={'width-1-14'}></td>
                        <td colSpan={1} className={'width-1-14'}></td>
                        <td colSpan={1} className={'width-1-14'}></td>
                        <td colSpan={1} className={'width-1-14'}></td>
                        <td colSpan={1} className={'width-1-14'}></td>
                    </tr>
                    { page === 'waiting' && <tr>
                        <td colSpan={4}>
                            <div className={`p-2 add-new-td add-new-td-left-top add-new-td-border-radius-top`}>
                                <p className={'m-0'}><small>{t('track_number')}</small></p>
                                <InputText
                                    id={CODE}
                                    required
                                    placeholder={t('track_number')}
                                    handleChange={handleValues}
                                    handleBlur={handleValues}
                                    errorMessage={''}
                                    value={barcode}
                                    validations={null}
                                    disabled={true}
                                />
                            </div>
                        </td>
                        <td colSpan={4}>
                            <div className={'p-2 add-new-td add-new-td-top'}>
                                <p className={'m-0'}><small>{t('dispatch_date')}</small></p>
                                <InputText
                                    id={DATE}
                                    required
                                    placeholder={t('dispatch_date')}
                                    handleChange={handleValues}
                                    handleBlur={handleValues}
                                    errorMessage={''}
                                    value={sentdate}
                                    validations={null}
                                    disabled={true}
                                />
                            </div>
                        </td>
                        <td colSpan={3}>
                            <div className={'p-2 add-new-td add-new-td-top'}>
                                <p className={'m-0'}><small>{t('tracking')}</small></p>
                                <InputText
                                    id={TRACKER}
                                    required
                                    placeholder={t('tracking')}
                                    handleChange={handleValues}
                                    handleBlur={handleValues}
                                    errorMessage={''}
                                    value={tracker}
                                    validations={null}
                                    disabled={true}
                                />
                            </div>
                        </td>
                        <td colSpan={3}>
                            <div className={'p-2 add-new-td add-new-td-right-top add-new-td-border-radius-right'}>
                                <p className={'m-0'}><small>{t('wh_short')}</small></p>
                                <InputText
                                    id={WAREHOUSE}
                                    required
                                    placeholder={t('wh_short')}
                                    handleChange={handleValues}
                                    handleBlur={handleValues}
                                    errorMessage={''}
                                    value={wh}
                                    validations={null}
                                    disabled={true}
                                />
                            </div>
                        </td>
                    </tr>}
                    <tr>
                        <td colSpan={countryWithServiceType ? 2 : 3}>
                            <div className={`p-2 add-new-td ${page === 'waiting' ? 'add-new-td-left' : 'add-new-td-left-top add-new-td-border-radius-top'}`}>
                                <p className={'m-0'}><small>{t('dest_country')}</small></p>
                                <Select
                                    id={TO_COUNTRY}
                                    required
                                    disabled={isDisabledDestCountry}
                                    options={countrySelectList}
                                    selectValue={to}
                                    nameValue={'country_name'}
                                    keyName={'country_id'}
                                    handleChange={handleValues}
                                    errorMessage={errorToCountry.message}
                                    validations={Object.values(validations[TO_COUNTRY])}
                                />
                            </div>
                        </td>
                        <td colSpan={countryWithServiceType ? 2 : 3}>
                            <div className={`p-2 add-new-td ${page === 'waiting' ? '' : 'add-new-td-top'}`}>
                                <p className={'m-0 position-relative'}>
                                    <small>{t('goods_type')}</small>
                                    <InfoQuestion className={'p-2 cursor-pointer'} text={`<div class="wb-normal">${t('tooltip_goods_type')}</div>`}/>
                                </p>
                                <Select
                                    id={SENSITIVE_GOODS}
                                    required
                                    options={goodTypeList}
                                    selectValue={goodsType}
                                    keyName={'value'}
                                    nameValue={'text'}
                                    handleChange={handleValues}
                                    errorMessage={errorSensitiveGoods.message}
                                    validations={Object.values(validations[SENSITIVE_GOODS])}
                                />
                            </div>
                        </td>
                        <td colSpan={2}>
                            <div className={`p-2 add-new-td ${page === 'waiting' ? '' : 'add-new-td-top'}`}>
                                <p className={'m-0'}><small>{t('send_by')}</small></p>
                                <Select
                                    id={SEND_BY}
                                    required
                                    options={sendByList}
                                    selectValue={sendBy}
                                    keyName={'value'}
                                    nameValue={'text'}
                                    handleChange={handleValues}
                                    errorMessage={errorSendBy.message}
                                    validations={Object.values(validations[SEND_BY])}
                                />
                            </div>
                        </td>
                        {
                            countryWithServiceType
                                ? <td colSpan={2}>
                                    <div className={`p-2 add-new-td ${page === 'waiting' ? '' : 'add-new-td-top'}`}>
                                        <p className={'m-0'}>
                                            <small>{t('TypeDelivery')}</small>
                                            <InfoQuestion className={'p-2 cursor-pointer'} text={''}/>
                                        </p>
                                        <Select
                                            id={SERVICE_TYPE}
                                            required
                                            disabled={disabledTypeOfDelivery}
                                            options={defServiceTypes}
                                            selectValue={serviceType}
                                            keyName={'value'}
                                            nameValue={'text'}
                                            handleChange={handleValues}
                                            errorMessage={errorServiceType.message}
                                            // isNotErrorAndShowMessage={!errorTelephone.isError}
                                            validations={null}
                                        />
                                    </div>
                                </td>
                                : null
                        }
                        <td colSpan={2}>
                            <div className={`p-2 add-new-td ${page === 'waiting' ? '' : 'add-new-td-top'}`}>
                                <p className={'m-0'}>
                                    <small>{t('qty')}</small>
                                    <InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>
                                </p>
                                <InputText
                                    id={QTY}
                                    placeholder={t('qty_placeholder')}
                                    value={qty}
                                    errorMessage={errorQty.message}
                                    handleChange={handleValues}
                                    handleBlur={handleValues}
                                    validations={Object.values(validations[QTY])}
                                    closeButton
                                />
                            </div>
                        </td>
                        <td colSpan={2}>
                            <div className={`p-2 add-new-td ${page === 'waiting' ? '' : 'add-new-td-top'}`}>
                                <p className={'m-0'}><small>{t('weight_kg')}</small></p>
                                <InputText
                                    id={WEIGHT}
                                    placeholder={t('weight_kg_placeholder')}
                                    handleChange={handleValues}
                                    handleBlur={handleValues}
                                    errorMessage={errorWeight.message}
                                    value={weight}
                                    validations={Object.values(validations[WEIGHT])}
                                    closeButton
                                    inputExclamationIcon={!errorWeight.isError && !!errorWeight.message.length}
                                />
                            </div>
                        </td>
                        <td colSpan={2}>
                            <div className={`p-2 add-new-td ${page === 'waiting' ? 'add-new-td-right' : 'add-new-td-right-top add-new-td-border-radius-right'}`}>
                                <p className={'m-0 position-relative'}>
                                    <small>{t('value_usd')}</small>
                                    {errorPrice.message === t('Value_has_been_changed') ?
                                        <InfoQuestion className={'p-2'} text={`<div class="wb-normal">${t('Value_has_been_changed_info')}</div>`} children={<span className={'my-text-danger'}>?</span>}/>
                                        : null
                                    }
                                </p>
                                <InputText
                                    id={PRICE}
                                    placeholder={t('value_usd_placeholder')}
                                    handleChange={(value, name, inputRef) => handleValues(value, name, false)}
                                    handleBlur={(value, name, inputRef) => {
                                        handleValues(value, name, inputRef)
                                        notificationTax(value, to)
                                    }}
                                    errorMessage={errorPrice.message}
                                    value={price}
                                    validations={Object.values(validations[PRICE])}
                                    closeButton
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <div className={`p-2 pb-4 add-new-td ${page === 'waiting' ? 'add-new-td-left' : 'add-new-td-left-bottom add-new-td-border-radius-left'}`}>
                                <p className={'m-0'}><small>{t('hs_code')}</small></p>
                                <InputText
                                    id={HS_CODE}
                                    placeholder={t('hs_code_placeholder')}
                                    handleChange={handleValues}
                                    handleBlur={handleValues}
                                    value={hsCode}
                                    errorMessage={errorHsCode.message}
                                    validations={Object.values(validations[HS_CODE])}
                                    closeButton
                                />
                            </div>
                        </td>
                        <td colSpan={2}>
                            <div className={`p-2 pb-4 add-new-td ${page === 'waiting' ? '' : 'add-new-td-bottom'}`}>
                                <p className={'m-0'}><small>{t('sku_code')}</small></p>
                                <InputText
                                    id={SKU}
                                    placeholder={t('sku_code_placeholder')}
                                    handleChange={handleValues}
                                    handleBlur={handleValues}
                                    value={sku}
                                    errorMessage={errorSKU.message}
                                    validations={Object.values(validations[SKU])}
                                    closeButton
                                />
                            </div>
                        </td>
                        <td colSpan={(associatedBarcodes && isEuropeCountry(to)) ? 2 : (isEuropeCountry(to) || associatedBarcodes) ? 3 : 4}>
                            <div className={`p-2 pb-4 add-new-td ${page === 'waiting' ? '' : 'add-new-td-bottom'}`}>
                                <p className={'m-0'}><small>{t('commodity')}</small></p>
                                <InputText
                                    id={COMMODITY}
                                    placeholder={t('commodity_placeholder')}
                                    handleChange={handleValues}
                                    handleBlur={handleValues}
                                    value={commodity}
                                    validations={Object.values(validations[COMMODITY])}
                                    errorMessage={errorCommodity.message}
                                    closeButton
                                    loaded={isLoadForbiddenCommodity}
                                />
                            </div>
                        </td>
                        {
                            associatedBarcodes
                                ?
                                <td colSpan={3}>
                                    <div
                                        className={`p-2 pb-4 add-new-td ${page === 'waiting' ? '' : 'add-new-td-bottom'}`}>
                                        <p className={'m-0'}><small>{t('last_mile_track_number')}</small></p>
                                        <InputText
                                            id={ASSOCIATED_BARCODES}
                                            placeholder={t('web_link_placeholder')}
                                            value={page === 'waiting' ? associatedBarcodes.join(', ')
                                                : ((typeof associatedBarcodes.sum === 'string') ? associatedBarcodes.sum : associatedBarcodes.sum.join(', '))
                                            }
                                            disabled
                                        />
                                    </div>
                                </td>
                                : null
                        }
                        <td colSpan={(associatedBarcodes && isEuropeCountry(to)) ? 3 : (isEuropeCountry(to) || associatedBarcodes) ? 4 : 7}>
                            <div
                                className={`p-2 pb-4 add-new-td ${page === 'waiting' ? (isEuropeCountry(to) ? '' : 'add-new-td-right') : isEuropeCountry(to) ? 'add-new-td-bottom' : 'add-new-td-bottom add-new-td-right add-new-td-border-radius-bottom'}`}>
                                <p className={'m-0'}><small>{t('web_link')}</small></p>
                                <InputText
                                    id={URL}
                                    placeholder={t('web_link_placeholder')}
                                    handleChange={handleValues}
                                    value={url}
                                    closeButton
                                />
                            </div>
                        </td>
                        {
                            isEuropeCountry(to)
                                ?
                                <>
                                    <td colSpan={associatedBarcodes ? 2 : 3}>
                                        {
                                            !useIOSS
                                                ?
                                                <div
                                                    className={`p-2 pb-4 add-new-td ${page === 'waiting' ? 'add-new-td-right' : 'add-new-td-right-bottom add-new-td-border-radius-bottom'}`}>
                                                    <div className={'m-0'}>
                                                        <small>{t('ioss_number')}</small>
                                                        <InfoQuestion className={'p-2 cursor-pointer'}
                                                                      text={t('tooltip_ioss')}/>
                                                        <Switch
                                                            id={'ioss_switch'}
                                                            defaultValue={useIOSS}
                                                            className={'d-inline-block'}
                                                            handle={handleUseSwitch}
                                                        />
                                                    </div>
                                                    <InputText
                                                        id={IOSS_NUMBER}
                                                        required
                                                        value={iossNumber}
                                                        handleChange={handleValues}
                                                        handleBlur={handleValues}
                                                        validations={Object.values(validations[IOSS_NUMBER])}
                                                        errorMessage={errorIoss.message}
                                                        closeButton
                                                    />
                                                </div>
                                                :
                                                <div
                                                    style={{
                                                        height: '99px'
                                                    }}
                                                    className={`p-2 add-new-td ${page === 'waiting' ? 'add-new-td-right' : 'add-new-td-right-bottom add-new-td-border-radius-bottom'} d-flex align-items-center`}>
                                                    <Switch
                                                        id={'ioss_switch'}
                                                        defaultValue={useIOSS}
                                                        handle={handleUseSwitch}
                                                        labelText={t('infoIOSS')}
                                                    />
                                                </div>
                                        }
                                    </td>
                                </>
                                : null
                        }

                    </tr>
                    { page === 'waiting' && <tr>
                        <td colSpan={4}>
                            <div className={`p-2 add-new-td add-new-td-left-bottom add-new-td-border-radius-left`}>
                                <p className={'m-0'}><small>{t('Domestic_express_tracking')}</small></p>
                                <InputText
                                    id={TRACK_NUMBER}
                                    required
                                    placeholder={t('Domestic_express_tracking')}
                                    handleChange={handleValues}
                                    handleBlur={handleValues}
                                    errorMessage={errorTracknum.message}
                                    value={tracknum}
                                    validations={null}
                                    closeButton
                                />
                            </div>
                        </td>
                        <td colSpan={4}>
                            <div className={'p-2 add-new-td add-new-td-bottom'}>
                                <p className={'m-0'}><small>{t('cnts')}</small></p>
                                <InputText
                                    id={CNTS}
                                    required
                                    placeholder={t('cnts')}
                                    handleChange={handleValues}
                                    handleBlur={handleValues}
                                    errorMessage={''}
                                    value={cnts}
                                    validations={null}
                                    disabled={true}
                                />
                            </div>
                        </td>
                        <td colSpan={6}>
                            <div
                                className={'p-2 pb-4 add-new-td add-new-td-right-bottom add-new-td-border-radius-bottom'}>
                                <p style={{visibility: 'hidden'}} className={'m-0'}><small>TMP</small></p>
                                <div style={{visibility: 'hidden'}}>
                                    <InputText
                                        id={'tmp'}
                                    />
                                </div>
                            </div>
                        </td>
                    </tr>}
                    <tr>
                        <td colSpan={14} className={'pt-3 background-wрite'}><strong>{t('ConsigneeDetails')}</strong></td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <div className={'p-2 add-new-td add-new-td-left-top add-new-td-border-radius-top'}>
                                <p className={'m-0 d-flex'}>
                                    <small className={'col'}>{t('receiver_name')}</small>
                                </p>
                                <Dropdown
                                    isOpen={openReceiver}
                                    onClose={() => setOpenReceiver(false)}
                                    target={
                                        <InputText
                                            attr={{
                                                autoComplete: "do-not-autofill"
                                            }}
                                            id={RECEIVER}
                                            placeholder={t('receiver_name_placeholder')}
                                            handleChange={(value, name) => {
                                                handleValues(value, name);
                                            }}
                                            handleFocus={() => {
                                                const trimValue = receiver.trim();
                                                filterReceiver(trimValue, RECEIVER)
                                                setOpenReceiver(true)
                                            }}
                                            handleBlur={(value, name) => {
                                                handleValues(value, name);
                                                setOpenReceiver(false);
                                            }}
                                            value={receiver}
                                            validations={Object.values(validations[RECEIVER])}
                                            hideSpamWithoutError={false}
                                            isNotErrorAndShowMessage={!errorReceiver.isError}
                                            smapClasses={!errorReceiver.isError && 'col-add'}
                                            errorMessage={errorReceiver.message}
                                            closeButton
                                        />
                                    }
                                >
                                  <SelectRS
                                    backspaceRemovesValue={false}
                                    controlShouldRenderValue={false}
                                    hideSelectedOptions={false}
                                    isClearable={false}
                                    menuIsOpen
                                    onChange={(newValue) => {
                                        setReceiverData(newValue.ID, RECEIVER)
                                        setOpenReceiver(false);
                                    }}
                                    styles={selectStyles}
                                    options={filterReceivers}
                                    getOptionValue ={(option)=>option.ID}
                                    // getOptionLabel ={(option)=>option.reciver}
                                    // value={receiverId}
                                  />
                                </Dropdown>
                            </div>
                        </td>
                        <td colSpan={3}>
                            <div className={'p-2 add-new-td add-new-td-top'}>
                                <p className={'m-0 d-flex'}>
                                    <small className={'col'}>{t('receiver_phone')}</small>
                                </p>
                                <Dropdown
                                    isOpen={openTel}
                                    onClose={() => setOpenTel(false)}
                                    target={
                                        <InputText
                                            attr={{
                                                autoComplete: "do-not-autofill"
                                            }}
                                            id={TELEPHONE}
                                            placeholder={t('receiver_phone_placeholder')}
                                            handleChange={(value, name, ref, eventType) => {
                                                handleValues(value, name, ref, eventType);
                                            }}
                                            handleFocus={() => {
                                                const trimValue = phone.trim();
                                                filterReceiver(trimValue, TELEPHONE)
                                                setOpenTel(true)
                                            }}
                                            handleBlur={(value, name) => {
                                                handleValues(value, name);
                                                setOpenTel(false);
                                            }}
                                            value={phone}
                                            validations={Object.values(validations[TELEPHONE])}
                                            hideSpamWithoutError={false}
                                            isNotErrorAndShowMessage={!errorTelephone.isError}
                                            smapClasses={(!errorTelephone.isError && !errorTelephone.showRed) && 'col-add'}
                                            errorMessage={errorTelephone.message}
                                            closeButton
                                        />
                                    }
                                >
                                  <SelectRS
                                    backspaceRemovesValue={false}
                                    controlShouldRenderValue={false}
                                    hideSelectedOptions={false}
                                    isClearable={false}
                                    menuIsOpen
                                    onChange={(newValue) => {
                                        setReceiverData(newValue.ID, TELEPHONE)
                                        setOpenTel(false);
                                    }}
                                    styles={selectStyles}
                                    options={filterReceivers}
                                    getOptionValue ={(option)=>option.ID}
                                  />
                                </Dropdown>
                            </div>
                        </td>
                        <td colSpan={4}>
                            <div className={'p-2 add-new-td add-new-td-top'}>
                                <p className={'m-0'}><small>{t('region')}</small></p>
                                <InputText
                                    id={REGION}
                                    placeholder={t('region_placeholder')}
                                    value={region}
                                    handleChange={handleValues}
                                    handleBlur={handleValues}
                                    validations={Object.values(validations[REGION])}
                                    errorMessage={errorRegion.message}
                                    closeButton
                                />
                            </div>
                        </td>
                        <td colSpan={4}>
                            <div className={'p-2 add-new-td add-new-td-right-top add-new-td-border-radius-right'}>
                                <p className={'m-0'}><small>{t('city')}</small></p>
                                <InputText
                                    id={CITY}
                                    placeholder={t('city_placeholder')}
                                    value={city}
                                    handleChange={handleValues}
                                    handleBlur={handleValues}
                                    validations={Object.values(validations[CITY])}
                                    errorMessage={errorCity.message}
                                    closeButton
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <div className={'p-2 add-new-td add-new-td-left'}>
                                <p className={'m-0'}><small>{t('zip')}</small></p>
                                <InputText
                                    id={ZIP}
                                    placeholder={t('zip_placeholder')}
                                    value={zip}
                                    handleChange={handleValues}
                                    handleBlur={handleValues}
                                    validations={Object.values(validations[ZIP])}
                                    isNotErrorAndShowMessage={!errorZip.isError}
                                    errorMessage={errorZip.message}
                                    smapClasses={!errorZip.isError && 'col-add'}
                                    closeButton
                                />
                            </div>
                        </td>
                        <td colSpan={3}>
                            <div className={'p-2 add-new-td'}>
                                <p className={'m-0'}><small>{t('street')}</small></p>
                                <InputText
                                    id={ADDRESS}
                                    placeholder={t('street_placeholder')}
                                    value={address}
                                    handleChange={handleValues}
                                    handleBlur={handleValues}
                                    validations={Object.values(validations[ADDRESS])}
                                    errorMessage={errorAddress.message}
                                    closeButton
                                />
                            </div>
                        </td>
                        <td colSpan={showInsurance ? 6 : 8}>
                            <div className={`p-2 add-new-td ${showInsurance ? '' : 'add-new-td-right'}`}>
                                <p className={'m-0'}><small>{t('receiver_email')}</small></p>
                                <InputText
                                    id={EMAIL}
                                    placeholder={t('receiver_email_placeholder')}
                                    handleChange={handleValues}
                                    value={email}
                                    errorMessage={errorEmail.message}
                                    validations={Object.values(validations[EMAIL])}
                                    closeButton
                                />
                            </div>
                        </td>
                        {
                            showInsurance
                                ?
                                <td colSpan={2}>
                                    <div
                                        className={'p-2 pb-4 add-new-td add-new-td-right'}>
                                        <div className={'m-0'}>
                                            <small>{t('Insurance')}</small>
                                            <InfoQuestion className={'p-2 cursor-pointer'}
                                                          text={t('tooltip_insurances') +
                                                              ` ${insuranceValue.InsuranceCost} ` +
                                                              (insuranceValue.currency === '156' ?
                                                                  t('CNY')
                                                                  : insuranceValue.currency === '840' ? t('USD') : '')}/>
                                            <Switch
                                                id={'insurance_switch'}
                                                defaultValue={insurance}
                                                className={'d-inline-block'}
                                                handle={switchInsurance}
                                            />
                                        </div>
                                        <InputText
                                            id={INSURANCE_VALUE}
                                            value={insurance ? insuranceValue.InsuranceCost : 0}
                                            disabled={true}
                                            errorMessage={errorInsurance.message}
                                        />
                                    </div>
                                </td>
                                : null
                        }
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <div
                                className={'p-2 pb-4 add-new-td add-new-td-left add-new-td-bottom add-new-td-border-radius-left'}>
                                <p className={'m-0'}><small>{t('passport_series')}</small></p>
                                <InputText
                                    id={PASSPORT}
                                    placeholder={t('passport_series_placeholder')}
                                    handleChange={handleValues}
                                    value={passport}
                                    errorMessage={errorPassport.message}
                                    validations={Object.values(validations[PASSPORT])}
                                    closeButton
                                />
                            </div>
                        </td>
                        <td colSpan={3}>
                            <div className={'p-2 pb-4 add-new-td add-new-td-bottom'}>
                                <p className={'m-0'}><small>{t('passport_number')}</small></p>
                                <InputText
                                    id={PASSPORT_NUMBER}
                                    placeholder={t('passport_number_placeholder')}
                                    handleChange={handleValues}
                                    value={passportNumber}
                                    closeButton
                                    errorMessage={errorPassportNumber.message}
                                    validations={Object.values(validations[PASSPORT_NUMBER])}
                                />
                            </div>
                        </td>
                        <td colSpan={3}>
                            <div className={'p-2 pb-4 add-new-td add-new-td-bottom'}>
                                <p className={'m-0'}>
                                    <small>{t('tax_id_number') + (to.country_id === 'MX' && sendBy.value === 'AIR' ? ' / CURP' : '')}</small>
                                    {to.country_id === 'MX' && sendBy.value === 'AIR' ? <InfoQuestion className={'p-2 cursor-pointer'} text={`<div class="wb-normal">${t('tooltip_curp')}</div>`}/> : null}
                                </p>
                                <InputText
                                    id={TAX_ID_NUMBER}
                                    placeholder={t('tax_id_number_placeholder')}
                                    handleChange={handleValues}
                                    value={taxId}
                                    closeButton
                                    errorMessage={errorTaxId.message}
                                    validations={Object.values(validations[TAX_ID_NUMBER])}
                                />
                            </div>
                        </td>
                        <td colSpan={6}>
                            <div
                                className={'p-2 pb-4 add-new-td add-new-td-right-bottom add-new-td-border-radius-bottom'}>
                                <p style={{visibility: 'hidden'}} className={'m-0'}><small>TMP</small></p>
                                <div style={{visibility: 'hidden'}}>
                                    <InputText
                                        id={'tmp'}
                                    />
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={14} className={'pt-3 background-wрite'}><small>{t('comments')}</small></td>
                    </tr>
                    <tr>
                        <td colSpan={14}>
                            <InputText
                                id={COMENT}
                                placeholder={t('comments_placeholder')}
                                value={comment}
                                handleChange={handleValues}
                                closeButton
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>
                <ModalButtons buttons={{
                    cancel: {
                        text: t('cancel'),
                        variant: 'outline-primary',
                        type: 'button',
                        action: hideForm,
                    },
                    save: {
                        text: t('save'),
                        variant: 'primary',
                        type: 'submit',
                        disabled: loadSpinner,
                    }
                }}/>
            </Form>
        </>
    );
};

export default NewParcelForm;

const TextForm = ({lang, countryList, europeCountry, setRefThese, refThese, handleHover}) => {

    if (lang === 'en') {
        return (<small>
            Due to the implementation of new customs clearance rules in EU since 2021.7.1 please
            fill in your personal IOSS ID number to create a parcel
            to <strong
            ref={ref => setRefThese(ref)}
            onMouseOver={event => handleHover(event, refThese)}
            onMouseOut={event => handleHover(event, refThese, false)}
            className={'cursor-pointer'}
            data-tip={countryList && countryList.length
                ? countryList
                    .filter(c => europeCountry.includes(c.country_id))
                    .map(c => c.country_name_en.toString().toUpperCase())
                    .join(', ')
                : ''}
            style={{textDecoration: 'underline'}}
        >these</strong> countries. Parcels with an incorrectly IOSS ID number may be
            detained by European customs. All data that are provided and filled in for this
            application are on the Client’s side responsibility.
        </small>)
    } else if (lang === 'cn') {
        return (<small>
            根据自2021年7月1日起欧盟实施新的清关规则，请填写您的个人IOSS税号，以便向<strong
            ref={ref => setRefThese(ref)}
            onMouseOver={event => handleHover(event, refThese)}
            onMouseOut={event => handleHover(event, refThese, false)}
            className={'cursor-pointer'}
            data-tip={countryList && countryList.length
                ? countryList
                    .filter(c => europeCountry.includes(c.country_id))
                    .map(c => c.country_name_zh.toString().toUpperCase() || c.country_name_en.toString().toUpperCase())
                    .join(', ')
                : ''}
            style={{textDecoration: 'underline'}}
        >这些</strong>国家创建包裹。IOSS 税号错误的包裹可能会被欧盟海关扣留。客户应对向本应用程序提供和填写的所有数据负责。
        </small>)
    }
    return null;
}

const selectStyles = {
    container: (provided) => ({
        ...provided,
        position: 'absolute',
        zIndex: 99,
        backgroundColor: '#fff',
        width: '400px',
        marginLeft: '17px',
    }),
    control: (provided) => ({
        ...provided,
        display: 'none'
    }),
    menu: () => ({
        border: '1px solid #000',
        boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)'
    }),
    menuList: (menuL) => ({
        ...menuL,
        maxHeight: '225px'
    }),
};
const Dropdown = ({
    children,
    isOpen,
    target,
    onClose,
}) => (
    <div style={{ position: 'relative' }}>
      {target}
      {isOpen ? <Menu>{children}</Menu> : null}
    </div>
);
const Menu = (props) => {
    return (
        <div style={{ position: 'relative' }} {...props}/>
    );
};
