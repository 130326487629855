import React, { useState } from 'react';
import PropTypes           from "prop-types";
import { useTranslation }  from "react-i18next";

import InputText           from "./InputText";

const InputPassword = ({
                           id,
                           type,
                           placeholder,
                           classes,
                           errorMessage,
                           required,
                           value,
                           closeButton,
                           handleChange,
                           handleBlur,
                           validations
}) => {

    const {t} = useTranslation();

    const [pass, setPass] = useState(value);

    function handleValue(value, name, inputRef) {
        if (typeof handleChange === 'function')
            handleChange(value, name, inputRef, 'change')
        setPass(value)
    }

    return (
        <InputText
            id={id ?? 'password'}
            type={type ?? 'password'}
            placeholder={placeholder ?? t('password')}
            classes={`form-placeholder form-input ${classes ?? ''}`}
            errorMessage={errorMessage}
            required={required}
            value={pass}
            handleChange={handleValue}
            handleBlur={handleBlur}
            validations={validations}
            closeButton={closeButton}
        />
    );
};

InputPassword.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    classes: PropTypes.string,
    errorMessage: PropTypes.string,
    required: PropTypes.bool
}

export default InputPassword;
