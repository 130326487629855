import React           from 'react';
import { useDispatch } from "react-redux";
import { useTranslation }           from "react-i18next";
import { trackEvent, AMPLITUDE_EVENTS } from "../../utils/amplitudeTracker";

import HelpVideo       from "./HelpVideo";
import Logo            from "../shared/Logo";
import BurgerMenu      from "../shared/BurgerMenu";
import { setShowMenu, setModalForm } from "../../storage/global";
import ButtonAdmin      from "../shared/pages_buttons/ButtonAdmin";
import FeedbackForm     from "../forms/FeedbackForm";
import { goToSite }     from "../../utils/misc";
import WriteReport   from "./WriteReport";

const HeaderSmall = ({
     className,
     onBackToWebsite,
     onFeedbackClick,
     onInstructionClick,
     onLanguageChange,
     onLogout,
     onNotificationClick,
     onProfileClick,
 }) => {
    const {t, i18n} = useTranslation();
    const lang = i18n.language || 'en';
    const dispatch = useDispatch();

    function showMenu(e) {
        trackEvent(AMPLITUDE_EVENTS.MENU_OPEN, {
            pageSection: 'header',
            deviceType: 'mobile'
        });
        dispatch(setShowMenu(true));
    }

    function onBackToWebsite(e) {
        goToSite(lang);
    }

    return (
        <div className={`d-flex ${className}`}>
            <div className={'col d-flex justify-content-start'}>
                <BurgerMenu
                    className={'cursor-pointer'}
                    handle={showMenu}
                />
                <WriteReport
                    classNameContainer={'ml-2 mt-1 d-flex align-items-center'}
                    underText={t('Feedback')}
                    isMobile={true}
                    onClick={onFeedbackClick}
                />
            </div>
            <Logo className={'col d-flex justify-content-center align-items-center'}
                  goTo={() => {
                    onBackToWebsite();
                    goToSite(lang);
                  }}
                  styleLogo={{
                      width: '72px',
                      marginTop: '-15px'
                  }}
            />
            <div className={'col d-flex justify-content-end'}>
                <HelpVideo
                    className={''}
                    onClick={onInstructionClick}
                />
            </div>
        </div>
    );
};

export default HeaderSmall;
