import React                        from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation }           from "react-i18next";

import Logo                         from "../shared/Logo";
import Divider                      from "../shared/Divider";
import LangBlock                    from "../shared/LangBlock";
import CloseButtonX                 from "../shared/CloseButtonX";
import NotifBtn                     from "../header/NotifBtn";
import ProfileButton                from "../header/ProfileButton";
import BalanceButton                from "../header/BalanceButton";
import LogoutButton                 from "../header/LogoutButton";
import ExcelTemplateAndImport       from "../header/ExcelTemplateAndImport";
import GoToSite                     from "../header/GoToSite";
import MenuItemGroup                from "../menu/MenuItemGroup";
import { setShowMenu }              from "../../storage/global";
import { goToSite }                 from "../../utils/misc";
import Faq from "./Faq";
import Wh from "./WH";
import UserQRCode from "./UserQRCode";

const MenuSmall = (props) => {

    const dispatch = useDispatch();
    const {i18n} = useTranslation();
    const lang = i18n.language || 'en';

    const showMenu = useSelector(state => state.global.showMenu);

    function hideSmallMenu() {
        dispatch(setShowMenu(false))
    }

    return (
        <>
            <aside
                className={`z-index-3 d-flex flex-column aside-small h-100 ${showMenu ? '' : 'hide-small-menu'}`}
            >
                <div className={'col-auto p-20'}>
                    <div className={'d-flex justify-content-between'}>
                        <Logo styleLogo={{width: '72px'}}
                              className={'d-flex justify-content-start align-items-center'}
                              goTo={() => goToSite(lang)}
                        />
                        <CloseButtonX className={'cursor-pointer'} handle={hideSmallMenu}/>
                    </div>
                </div>
                <div className={'col overflow-auto d-flex flex-column blue-scroll'}>
                    <div className={'col'}>
                        <div className={'p-20 pb-0'}>
                            <ProfileButton className={'pb-3 cursor-pointer'} isMobile={true}/>
                            <div className={'d-flex justify-content-between align-items-center'}>
                                <BalanceButton/>
                            </div>
                            <Divider className={'mt-4 mb-4'}/>
                            <div className={'d-flex justify-content-between align-items-center'}>
                                <LangBlock className={'text-right'}/>
                            </div>
                            <Divider className={'mt-4 mb-4'}/>
                            <div className={'d-flex justify-content-between align-items-center'}>
                                <NotifBtn isMobile={true}/>
                            </div>
                            <Divider className={'mt-4 mb-4'}/>
                            <ExcelTemplateAndImport className={''}/>
                            <Divider className={'mt-4'}/>
                            <GoToSite isMobile={true}/>
                            <Divider className={'mt-4'}/>
                        </div>
                        <MenuItemGroup navList={props.navList}/>
                        <Wh/>
                        <UserQRCode/>
                        <Faq/>
                    </div>
                    <div className={'col-auto'}>
                        <LogoutButton className={'p-20'}/>
                    </div>
                </div>
                {/*<div className={'container-fluid p-0 h-100'}>*/}
                {/*    <div className={'row flex-column justify-content-between h-100'}>*/}
                {/*        <div className={'col'}>*/}
                {/*            */}
                {/*        </div>*/}
                {/*        <div className={'col-auto'}>*/}
                {/*            <LogoutButton className={'p-20'}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </aside>
            {/*<div*/}
            {/*    onClick={hideSmallMenu}*/}
            {/*    className={`background-small-menu ${showMenu ? 'opacity-1 z-index-unset' : ''}`}*/}
            {/*/>*/}
        </>
    );
};

export default MenuSmall;
