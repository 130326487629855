import React, { memo }                                     from 'react';

const RowList = ({className, item, children, uniqueId, click, isSelected, doubleClick}) => {

    const ref = React.createRef();

    function onClick(event) {
        if (typeof click === 'function')
            click(event, item, ref);
    }

    function onDoubleClick(event) {
        if (typeof doubleClick === 'function')
            doubleClick(event, item, ref)
    }

    return (
        <div
            ref={ref}
            data-uid={item[uniqueId]}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            className={`${className ? className : ''} row g-0 table-row-item ${isSelected ? 'table-item-selected' : ''}`}>
            <div className={'my-col-24 d-flex align-items-center'}>
                <div className={'container-fluid p-0'}>
                    <div className={'row g-0'}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(RowList);
