import React, { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector }                  from "react-redux";
import { useTranslation }                            from "react-i18next";
import moment                                        from "moment/moment";
import { useLocation } from "react-router-dom";

import { trackEvent, AMPLITUDE_EVENTS } from "../../utils/amplitudeTracker";


import {
    setAlertShow,
    setCurrentPage,
    setList,
    setLoadSpinner,
    setModalForm,
    setSearch,
    setSelectedList,
    setGroupSearchesCheckboxes,
    SET_KEY,
}                                                    from "../../storage/global";
import {
    deleteNewParcels,
    exportNewParcels,
    getParcelInfo,
}                                                    from "../../utils/api";
import {
    goToSiteTracking,
    requestDeleteMethod,
    viewAlert,
    getList,
}                                                    from "../../utils/misc";
import {
    CODE,
    COMMODITY,
    CONSIGNEE,
    DATE,
    DESC,
    QTY,
    routesNames,
    SELECTED,
    SEND_BY,
    COMENT,
    TO_COUNTRY,
    TRACK_NUMBER,
    TRACKER,
    WEIGHT
}                              from "../../constants";

import ActionBlock                        from "./ActionBlock";
import NewParcelForm                      from "../../components/forms/NewParcelForm";
import PrintSettingsForm                  from "../../components/forms/PrintSettingsForm";
import DeleteParcelsForm                  from "../../components/forms/DeleteParcelsForm";
import CheckBox                           from "../../components/input/CheckBox";
import PageMenu                           from "../../components/shared/PageMenu";
import CheckboxList                       from "../../components/shared/CheckboxList";
import PageListTemplate                   from "../../components/shared/PageListTemplate";
import EditButton                         from "../../components/shared/pages_buttons/EditButton";
import DeleteButton                       from "../../components/shared/pages_buttons/DeleteButton";
import PrintStickerButton                 from "../../components/shared/pages_buttons/PrintStickerButton";
import ExportButton                       from "../../components/shared/pages_buttons/ExportButton";
import ButtonStyleAction                  from "../../components/shared/pages_buttons/ButtonStyleAction";
import FilterListBtn                      from "../../components/shared/pages_buttons/FilterListBtn";
import SpinnerRaccoon                     from "../../components/shared/SpinnerRaccoon";

const NewParcels = () => {

    const date = moment();
    const filename = `new_parcels_${date.format('YYYY-MM-DD_HH_mm')}.xlsx`;

    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    const list = useSelector(state => state.global.list);
    const groupSearchesCheckboxes = useSelector(state => state.global.groupSearchesCheckboxes.newParcels);
    const withGroupFilter = useSelector(state => state.global.groupSearchesCheckboxes.withGroupFilter);

    const [checkedAll, setCheckedAll] = useState(false);
    const [listAfterFilter, setListAfterFilter] = useState([]);

    const groupSearchesCheckboxesCodes = useMemo(() => Object.keys(groupSearchesCheckboxes).filter( code => groupSearchesCheckboxes[code]), [groupSearchesCheckboxes])

    const order = {
        direction: DESC,
        name: DATE
    };

    const deleteFormData = {
        title: t('delete_selected_parcels'),
        form: <DeleteParcelsForm
            deleteMethod={requestDeleteMethod(dispatch, () => getList(dispatch, t, routesNames.NEW_PARCELS), deleteNewParcels, t)}
            text={t('delete_parcels_text_1')}
        />,
    }
    // //Clear before destroy component
    useEffect(() => {
        dispatch(setCurrentPage(routesNames.NEW_PARCELS))
        return () => {
            dispatch(setModalForm({show: false}))
            dispatch(setAlertShow(false))
            dispatch(setSearch(''))
            dispatch(setList([]))
            dispatch(setSelectedList([]))
            dispatch({type: SET_KEY, payload: {
                key: 'applyFilter',
                value: false,
            }})
        }
    }, [])

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const action = params.get('action');

        if (action === 'create-new-parcel') {
            dispatch(setModalForm({
                title: '',
                form: <NewParcelForm
                    getListParcels={() => getList(dispatch, t)}
                />,
                fullScreen: true,
                hideOut: false,
            }));

            const newUrl = window.location.pathname;
            window.history.pushState({}, '', newUrl);
        }
    }, [location.search]);

    useMemo(() => {
        const isCheck:boolean = !!list.filter(parcel => parcel.check).length
        setCheckedAll(isCheck)
    }, [list])

    function handleSearch(search) {
        dispatch(setSelectedList([]));
    }


    function handleExport() {
        const sendList = list.filter(parcel => parcel.check);
        trackEvent(AMPLITUDE_EVENTS.EXPORT_NEW_PARCELS, {
            pageSection: 'new_parcels',
            itemsCount: sendList.length
        });

        sendList.forEach(el => {
            let service_type = el.service_type;
            if(el.service_type === "0") service_type = 'TO DOOR';
            if(el.service_type === "1") service_type = 'PUDO';
            el.service_type = service_type;
        });
        return exportNewParcels(sendList);
    }

    function openGroupSearch() {
        trackEvent(AMPLITUDE_EVENTS.FILTER_NEW_PARCELS, {
            pageSection: 'new_parcels',
            filterType: 'group_search'
        });
        dispatch(setModalForm({
            title: t('GroupSearchMenu'),
            form: <CheckboxList list={groupSearchesCheckboxes} setList={saveAndApplyGroupSearch}/>
        }));
    }

    function saveAndApplyGroupSearch(list) {
      dispatch(setGroupSearchesCheckboxes({
          page: 'newParcels',
          val: list
      }))
    }

    function handlePrint() {
        trackEvent(AMPLITUDE_EVENTS.PRINT_STICKER_NEW_PARCELS, {
            pageSection: 'new_parcels',
            selectedCount: list.filter(item => item.check).length
        });
        dispatch(setModalForm({
            title: t('printSettings'),
            form: <PrintSettingsForm/>,
            fullScreen: true,
        }));
    }

    function handleAddNew() {
        trackEvent(AMPLITUDE_EVENTS.ADD_NEW_NEW_PARCELS, {
            pageSection: 'new_parcels'
        });
        dispatch(setModalForm({
            title: '',
            form: <NewParcelForm getListParcels={() => getList(dispatch, t, routesNames.NEW_PARCELS)}/>,
            fullScreen: true,
            hideOut: false,
        }));
    }

    function handleEdit(parcel) {
        trackEvent(AMPLITUDE_EVENTS.EDIT_NEW_PARCELS, {
            pageSection: 'new_parcels',
            parcelCode: parcel?.code
        });

        const uid = parcel.uid;
        dispatch(setLoadSpinner(true));
        getParcelInfo({uid})
            .then(({data}) => {
                dispatch(setModalForm({
                    title: '',
                    form: <NewParcelForm data={{...parcel, ...data.item}} isEdit getListParcels={() => getList(dispatch, t, routesNames.NEW_PARCELS)}/>,
                    fullScreen: true,
                    hideOut: false,
                }));
            })
            .catch(({response}) => {
                viewAlert(dispatch, response, {title: t('error')});
            })
            .finally(() => dispatch(setLoadSpinner(false)));
    }

    function handleDoubleClick(event, parcel, ref) {
        handleEdit(parcel)
    }

    function handleAllChek(value) {
        const newList = [...list];
        (listAfterFilter && listAfterFilter.length ? listAfterFilter : list).forEach(parcel => {
            const numEl = list.findIndex( el => el.uid === parcel.uid)
            if (numEl >= 0) newList[numEl].check = value;
        });
        dispatch(setList(newList));
        setCheckedAll(value)
    }

    function selectAll() {
        trackEvent(AMPLITUDE_EVENTS.SELECT_ALL_PARCELS, {
            pageSection: 'new_parcels',
            selected: !checkedAll,
            itemsCount: list.length
        });
        handleAllChek(!checkedAll);
    }

    function handleCheck(value, item, ref) {
        trackEvent(AMPLITUDE_EVENTS.SELECT_PARCEL, {
            pageSection: 'new_parcels',
            parcelCode: item.code,
            selected: !item.check
        });
        dispatch(setList(list.map(parcel => {
            if (item.uid === parcel.uid) {
                parcel.check = !parcel.check;
            }
            return parcel;
        })));
    }

    const listHead = [
        {
            name: SELECTED, col: 'flex-0-0-0', text: '+', sortArrow: false, getChildren: (v, k) => {
                return <CheckBox
                    id={'all-check'}
                    className={'check-input'}
                    styles={{fontSize: '20px'}}
                    handle={handleAllChek}
                    value={checkedAll}
                />
            }
        },
        {name: TRACK_NUMBER, col: 4, text: t('track_number'), sortArrow: false, withoutSort: true},
        {name: DATE, col: 2, text: t('date_of_creating')},
        {name: COMMODITY, col: 2, text: t('commodity')},
        {name: TRACKER, col: 3, text: t('tracking')},
        // {name: SERVICE_TYPE, col: 2, text: t('TypeDelivery')},
        // {name: QTY, col: 1, text: t('qty_2')},
        {name: WEIGHT, col: 2, text: t('weight')},
        // {name: PRICE, col: 1, text: t('value')},
        {name: SEND_BY, col: 2, text: t('ship')},
        {name: TO_COUNTRY, col: 2, text: t('to')},
        {name: CONSIGNEE, col: 3, text: t('consignee')},
        // {name: ADDRESS, col: 2, text: t('address')},
        // {name: SENSITIVE_GOODS, col: 2, text: t('sn_goods')},
        // {name: TELEPHONE, col: 3, text: t('telephone')},
        {name: COMENT, col: 3, text: t('comment'), sortArrow: false, withoutSort: true},
    ]

    const listItem = [
        {
            name: SELECTED, col: 'flex-0-0-0', isTrackNumber: false,
            getChildren: (parcel) => {
                return <CheckBox
                    className={'check-input mt-children-input-0'}
                    styles={{fontSize: '20px'}}
                    handle={(value, id, ref) => handleCheck(value, parcel, ref)}
                    value={!!parcel.check}
                />
            }
        },
        {
            name: CODE, col: 4, isTrackNumber: true,
            click: (event, parcel, ref) => {
                goToSiteTracking(i18n.language, parcel.code);
            },
            attr: {"no-select": 'no-select'},
            copy: true,
            classTableItem: 'cursor-pointer'
        },
        {name: DATE, col: 2, isTrackNumber: false},
        {name: COMMODITY, col: 2, isTrackNumber: false},
        {name: TRACKER, col: 3, isTrackNumber: false, afterLoadData: true},
        // {
        //     name: SERVICE_TYPE,
        //     col: 2,
        //     isTrackNumber: false,
        //     getChildren: (parcel) => {
        //         if(parcel.service_type === "0") return t('toDoor')
        //         if(parcel.service_type === "1") return t('PUDO')
        //         return t('error')
        //     }
        // },
        // {name: QTY, col: 1, isTrackNumber: false},
        {name: WEIGHT, col: 2, isTrackNumber: false},
        // {name: PRICE, col: 1, isTrackNumber: false},
        {name: SEND_BY, col: 2, isTrackNumber: false},
        {name: TO_COUNTRY, col: 2, isTrackNumber: false},
        {name: CONSIGNEE, col: 3, isTrackNumber: false},
        // {name: ADDRESS, col: 2, isTrackNumber: false},
        // {name: SENSITIVE_GOODS, col: 2, isTrackNumber: false},
        // {name: TELEPHONE, col: 3, isTrackNumber: false},
        {name: COMENT, col: 3, isTrackNumber: false},
    ]

    const listItemMobile = {
        firstColumn: [
            {
                name: CODE, nameColumn: t('track_number'), col: 24, isTrackNumber: true,
                click: (event, parcel, ref) => {
                    goToSiteTracking(i18n.language, parcel.code);
                },
                attr: {"no-select": 'no-select'},
                copy: true
            },
            {name: COMMODITY, nameColumn: t('commodity'), col: 24, isTrackNumber: false},
            {name: TO_COUNTRY, nameColumn: t('to'), col: 24, isTrackNumber: false},
            {name: TRACKER, nameColumn: t('tracking'), col: 24, isTrackNumber: false, afterLoadData: true},
        ],
        secondColumn: [
            {name: DATE, nameColumn: t('date_of_creating'), col: 14, isTrackNumber: false},
            {
                name: SELECTED, nameColumn: t(''), col: 10, isTrackNumber: false, classTableItem: 'w-100',
                getChildren: (parcel) => {
                    return <div className={'d-flex justify-content-end'}>
                        <CheckBox
                            className={'check-input'}
                            styles={{fontSize: '20px'}}
                            handle={(value, id, ref) => handleCheck(value, parcel, ref)}
                            value={!!parcel.check}
                        />
                    </div>
                }
            },
            {name: QTY, nameColumn: t('qty_2'), col: 8, isTrackNumber: false},
            {name: WEIGHT, nameColumn: t('weight'), col: 8, isTrackNumber: false},
            {name: SEND_BY, nameColumn: t('ship'), col: 8, isTrackNumber: false},
            {name: CONSIGNEE, nameColumn: t('consignee'), col: 24, isTrackNumber: false},
            // {
            //     name: SERVICE_TYPE,
            //     nameColumn: t('TypeDelivery'),
            //     col: 24,
            //     isTrackNumber: false,
            //     getChildren: (parcel) => {
            //         if(parcel.service_type === "0") return <div className={``}>
            //             {t('toDoor')}
            //         </div>
            //         if(parcel.service_type === "1") return <div className={``}>
            //             {t('PUDO')}
            //         </div>
            //         return <div className={``}>
            //         {t('error')}
            //     </div>
            //     }
            // },
        ]
    }

    function setDataAfterFilter(data) {
        setListAfterFilter(data)
    }

    return (
        <div className={'container-page h-100 p-relative'}>
            <SpinnerRaccoon/>
            <ActionBlock
                handleSearch={handleSearch}
                deleteFormData={deleteFormData}
                handleExport={handleExport}
                openGroupSearch={openGroupSearch}
                exportFilename={filename}
                handlePrint={handlePrint}
                handleAddNew={handleAddNew}
                handleEdit={handleEdit}
                handle={selectAll}
                isSelectedAll={checkedAll}
            />
            <PageListTemplate
                srcOrder={order}
                uniqueRowId={'uid'}
                listHead={listHead}
                listItem={listItem}
                listItemMobile={listItemMobile}
                emptyListText={t('dontHaveAnyParcel')}
                disableSelect
                getDataMethod={() => getList(dispatch, t, routesNames.NEW_PARCELS)}
                handleDoubleClick={handleDoubleClick}
                listForFilter={withGroupFilter ? groupSearchesCheckboxesCodes : null}
                orderedHook={ data => setDataAfterFilter(data)}
                page={'newParcels'}
            />
            <PageMenu stylesMenuList={{width: '13rem'}}>
                <FilterListBtn isMobile={true} page={'newParcels'}/>
                <EditButton handle={handleEdit}/>
                <DeleteButton deleteFormData={deleteFormData}/>
                <PrintStickerButton handle={handlePrint}/>
                <ExportButton handle={handleExport} filename={filename}/>
                <ButtonStyleAction handle={openGroupSearch} text={'GroupSearch'} iconClasses={'fa-search mr-1'}/>
            </PageMenu>
        </div>
    )
}

export default memo(NewParcels);
