
import React, {useState}    from 'react';
import ReactTooltip         from "react-tooltip";

const PromocodesCountriesList = ({text = '', countriesText = ''}) => {
    
    const [ref, setRef] = useState({});
    
    function handleHover(event, ref_, isHover = true) {
        event.preventDefault();
        event.stopPropagation();
        if (isHover)
            ReactTooltip.show(ref_)
        else
            ReactTooltip.hide(ref_)
    }

    return (
        <span className={`cursor-pointer`}
              ref={ref => setRef(ref)}
              data-html="true"
              data-tip={`<div class="wb-normal">${countriesText}</div>`}
              onMouseOver={event =>  handleHover(event, ref)}
              onMouseOut={event => handleHover(event, ref, false)}
              dangerouslySetInnerHTML={{ __html: text }}
        >
        </span>
    )
};

export default PromocodesCountriesList;
