import React, {useEffect, useState} from 'react';
import {Button, Form} from "react-bootstrap";
import PropTypes from "prop-types";

import {validate} from "../../utils/misc";
import {DEFAULT_ERROR_OBJECT} from "../../constants";
import LoadInput from "../shared/LoadInput";
import CloseInputButton from "../shared/CloseInputButton";
import ShowHidePwd from "../shared/ShowHidePwd";
import InputSelectErrorBlock from "../shared/InputSelectErrorBlock";
import InputExclamationIcon from "../shared/InputExclamationIcon";

const InputText = ({
                       id,
                       type,
                       placeholder,
                       classes,
                       required,
                       value: v,
                       as,
                       rows,
                       errorMessage,
                       handleBlur,
                       handleFocus,
                       closeButton,
                       handleChange,
                       handleInput,
                       loaded,
                       disabled,
                       hidden,
                       validations,
                       errorStyles,
                       errorWithTitle,
                       acceptFiles,
                       label,
                       selectLabelText,
                       attr,
                       styles,
                       dataTip,
                       groupClssses,
                       smapClasses,
                       hideSpamWithoutError,
                       isNotErrorAndShowMessage,
                       dangerFileInput,
                       multiple,
                       labelClssses,
                       buttonClssses,
                       classNamesClear,
                       isValid,
                       inputExclamationIcon,
                   }) => {

    const inputRef = React.createRef();
    const refLabel = React.createRef();
    // const {t} = useTranslation();

    const [value, setValue] = useState(v ?? '');
    const [typeControl, setTypeControl] = useState(type ?? 'text');
    const [error, setError] = useState({...DEFAULT_ERROR_OBJECT})

    useEffect(() => {
        setValue(v ?? '')
    }, [v])

    useEffect(() => {
        setTypeControl(type ?? 'text')
    }, [type])

    function onChange(event) {
        let str = event.target.value;
        let handleChangeRes = '';
        if (typeof handleChange === 'function') {
            handleChangeRes = handleChange(str, id, inputRef, 'change')
        } else {
            validate(str, validations, setError, DEFAULT_ERROR_OBJECT)
        }
        if(handleChangeRes !== 'byValue') setValue(str)
    }

    function onInput(event) {
        let str = event.currentTarget.value;
        if (typeof handleInput === 'function') {
            handleInput(str, id, inputRef);
        }
    }

    function onBlur(event) {
        if (typeof handleBlur === "function")
            handleBlur(event.currentTarget.value, id, inputRef)
    }

    function onFocus(event) {
        if (typeof handleFocus === "function")
            handleFocus(event.currentTarget.value, id, inputRef)
    }

    function onKey(event) {
        if (event.key === 'Enter')
            onBlur(event)
    }

    function clear() {
        const str = '';
        if (v === undefined) {
            setValue(str)
            inputRef.current.value = '';
        }
        if (typeof handleBlur === "function")
            handleBlur(str)
        onChange({target: {value: str}})
    }

    return (
        <Form.Group controlId={id} className={`position-relative ${groupClssses ?? ''}`}>
            <Form.Control
                data-tip={dataTip}
                style={{
                    height: '42px',
                    ...styles,
                }}
                {...attr}
                ref={inputRef}
                resize={'none'}
                as={as ? as : 'input'}
                rows={rows ? rows : 1}
                // required={required}
                className={`form-placeholder form-input ${classes ?? ''} ${closeButton ?
                    'padding-when-clear-search-button-viewed' : ''} ${inputExclamationIcon ? 'padding-when-exclamation-viewed' : ''}`}
                type={typeControl}
                placeholder={placeholder}
                isInvalid={error.isError || (errorMessage && errorMessage.length && !isNotErrorAndShowMessage) || (required && !value.length)}
                value={value}
                onChange={onChange}
                // onInput={onInput}
                onInputCapture={onInput}
                onBlur={onBlur}
                onFocus={onFocus}
                onKeyPress={onKey}
                disabled={disabled || loaded}
                hidden={hidden}
                accept={acceptFiles}
                multiple={multiple}
                isValid={isValid}
            />
            <Form.Label ref={refLabel} className={`w-100 ${labelClssses || ''}`} hidden={!label}>
                <Button onClick={event => refLabel.current.click()}
                        variant={dangerFileInput ? 'outline-danger' : 'outline-primary'}
                        className={`w-100 ${buttonClssses} ${closeButton && value.toString().length ? 'pr-30' : ''}`}>
                    {selectLabelText}
                </Button>
            </Form.Label>
            {inputExclamationIcon && <InputExclamationIcon/>}
            <ShowHidePwd onClick={() => setTypeControl(typeControl === 'password' ? 'text' : 'password')}
                         passwordTYpe={typeControl === 'password'}
                         showIcon={type === 'password' && value.toString().length}/>
            <CloseInputButton clear={clear} closeButton={closeButton && value.toString().length}
                              classNames={classNamesClear}/>
            <LoadInput loaded={loaded}/>
            {(!hideSpamWithoutError || (hideSpamWithoutError && errorMessage)) && <InputSelectErrorBlock
                styles={errorStyles}
                show={error.isError || !!(errorMessage && errorMessage.length)}
                errorMessage={errorMessage}
                smapClasses={smapClasses}
                title={errorWithTitle ? errorMessage : ''}
            />}
        </Form.Group>
    );
};

InputText.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    classes: PropTypes.string,
    errorMessage: PropTypes.string,
    required: PropTypes.bool
}

export default InputText;
