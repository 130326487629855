import React, { useEffect, useState, useMemo }          from 'react';
import { useDispatch }                                  from "react-redux";
import { Button }                                       from "react-bootstrap";
import { useTranslation }                               from "react-i18next";

import {
    REQ_URI,
    WARNING_NOTIF_MAX_VALUE,
}                                                       from "../../../constants";
import { setLoad, setLoadSpinner }                      from "../../../storage/global";
import {
    getRequestsUris,
    getQueryStatisticsListAvg,
    saveWarningNotificationLimits,
    getWarningNotificationLimits,
}                                                       from "../../../utils/api";
import { viewAlert }                                    from "../../../utils/misc";
import Select                                           from "../../input/Select";
import RequestStatistic                                 from "./RequestStatistic";
import InputText                                        from "../../input/InputText";
import CheckBox                                         from "../../input/CheckBox";

const StatisticLimits = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const defStorage = {
        items: [],
        defUrlLimits: [],
        defUrlLists: [],
    };

    const nothingSelected = t('nothingSelected');
    const [urls, setUrls] = useState([nothingSelected]);
    const [urlForGetResult, setUrlForGetResult] = useState('');

    const [storage, setStorage] = useState(defStorage);
    const [onlyInstalled, setOnlyInstalled] = useState(false);
    const [stepFilter, setStepFilter] = useState('');

    const {items, defUrlLimits, defUrlLists, urlListWithLimits} = storage;

    useEffect(() => {
        dispatch(setLoad(false))
        getQueryStatisticsAvg()
        getRequestsUriList()
    }, [])

    useEffect(() => {
        if(onlyInstalled) {
            setStepFilter('start');
            getQueryStatisticsAvg(true);
        } else {
            getQueryStatisticsAvg();
        }
    }, [onlyInstalled])

    useEffect(() => {
        if(stepFilter === 'filter') {
            let itemsWithLimits = items;
            if(items.length) {
                itemsWithLimits = items.filter((url) => {
                    return +url[WARNING_NOTIF_MAX_VALUE] > 0
                })
            }
            setStorage({
                ...storage,
                items: itemsWithLimits.sort(function(a, b) {return +b.warningNotifMaxValue - +a.warningNotifMaxValue}),
            });
            setStepFilter('');
        }
    }, [stepFilter])

    function getQueryStatisticsAvg(aftrerload) {
        dispatch(setLoadSpinner(true))
        getQueryStatisticsListAvg().then(responseListAvg => {
                return getWarningNotificationLimits().then(responseLimits => {

                    let urlLimits = defUrlLimits;
                    if(responseLimits?.data?.urls?.length) {
                        urlLimits = responseLimits?.data?.urls;
                    }
                    let itemsWithLimits = items;
                    let urlListWithLimits_ = [];
                    if(responseListAvg?.data?.queryStatistics?.length) {
                        itemsWithLimits = responseListAvg?.data?.queryStatistics.map((url)=>{
                            const existUrl = urlLimits.length && urlLimits.find((urlSett)=> urlSett.req_uri===url.req_uri);
                            const newMax = existUrl ?
                                urlLimits.find((urlSett)=> urlSett.req_uri===url.req_uri)[WARNING_NOTIF_MAX_VALUE]
                                : ''
                            return {
                                ...url,
                                [WARNING_NOTIF_MAX_VALUE]: newMax
                            }
                        })
                        urlListWithLimits_ = itemsWithLimits.filter((url) => {
                            return +url[WARNING_NOTIF_MAX_VALUE] > 0
                        })
                    }
                    setStorage({
                        items: itemsWithLimits.sort(function(a, b) {
                            return b.warningNotifMaxValue - a.warningNotifMaxValue
                        }),
                        defUrlLimits: urlLimits,
                        defUrlLists: responseListAvg?.data?.queryStatistics,
                        urlListWithLimits: urlListWithLimits_,
                    });
                    aftrerload && setStepFilter('filter');
                })
            })
            .catch(({response}) => {viewAlert(dispatch, response)})
            .finally(() => dispatch(setLoadSpinner(false)))
    }

    function getRequestsUriList() {
        dispatch(setLoadSpinner(true))
        getRequestsUris().then(response => {
                setUrls([nothingSelected, ...Object.values(response.data.uris).map(row=>row.req_uri)]);
            })
            .catch(({response}) => {viewAlert(dispatch, response)})
            .finally(() => dispatch(setLoadSpinner(false)))
    }

    function loadData() {
        if(+urlForGetResult) {
            let list = defUrlLists.filter((url) => {
                return url.req_uri ===  urls[urlForGetResult]
            });
            let itemsWithLimits = list.map((url)=>{
                const existUrl = defUrlLimits.length && defUrlLimits.find((urlSett)=> urlSett.req_uri===url.req_uri);
                const newMax = existUrl ?
                    defUrlLimits.find((urlSett)=> urlSett.req_uri===url.req_uri)[WARNING_NOTIF_MAX_VALUE]
                    : ''
                return {
                    ...url,
                    [WARNING_NOTIF_MAX_VALUE]: newMax
                }
            })
            setStorage({
                ...storage,
                items: itemsWithLimits.sort(function(a, b) {return b.warningNotifMaxValue - a.warningNotifMaxValue}),
            });
        } else {
            getQueryStatisticsAvg();
        }
    }

    function changeLimit(val, i) {
        const withNewLimits = [...items];
        withNewLimits[i][WARNING_NOTIF_MAX_VALUE] = val;
        setStorage({
            ...storage,
            items: withNewLimits,
        });
    }

    async function saveReqLimits() {
        dispatch(setLoadSpinner(true))
        try {
            const newList = urlListWithLimits.map(url_obj=>{
                const existUrl = items.length && items.find((urlSett)=> urlSett.req_uri===url_obj.req_uri);
                const newMax = existUrl ?
                    items.find((urlSett)=> urlSett.req_uri===url_obj.req_uri)[WARNING_NOTIF_MAX_VALUE]
                    : url_obj[WARNING_NOTIF_MAX_VALUE]
                return {
                    ...url_obj,
                    [WARNING_NOTIF_MAX_VALUE]: newMax
                }
            })
            items.forEach(url_obj=>{
                if(urlListWithLimits.length) {
                    const existUrl = urlListWithLimits.find((urlSett)=> urlSett.req_uri===url_obj.req_uri);
                    if(!existUrl) {
                        newList.push(url_obj);
                    }
                } else {
                    newList.push(url_obj);
                }
            })
            const warningNotificationUrls = newList
                .filter(url_obj=>url_obj[WARNING_NOTIF_MAX_VALUE] > 0)
                .map(url_obj=>({req_uri: url_obj.req_uri, [WARNING_NOTIF_MAX_VALUE]: url_obj[WARNING_NOTIF_MAX_VALUE]}))
            const responseDangerWorlds = await saveWarningNotificationLimits({warningNotificationUrls});
            viewAlert(dispatch, {responseDangerWorlds})
        } catch(e) {
            viewAlert(dispatch, {"err": e})
        }
        dispatch(setLoadSpinner(false))
    }

    const listHead = [
        {name: REQ_URI, col: 10, text: t('list_head_req_uri'), sortArrow: false, classNames: 'wfc minw-100'},
        {name: WARNING_NOTIF_MAX_VALUE, col: 8, text: t('warning_notification_max_value'), sortArrow: false, classNames: 'wfc minw-100'},
    ]
    const listItem = [
        {name: REQ_URI, col:10, classNames: 'wfc minw-100 py-1'},
        {name: WARNING_NOTIF_MAX_VALUE, col: 8, classNames: 'wfc minw-100 px-0 py-1', getChildren: (index, key) => {
            return <InputText handleChange={(val)=>changeLimit(val, index)} type='number' value={items[index][WARNING_NOTIF_MAX_VALUE]} id={WARNING_NOTIF_MAX_VALUE}
                placeholder={'ліміт'} closeButton/>
        }},
    ]

    return (
        <>
            <div className={'v-card v-sheet theme--light p-3 d-flex flex-column '}>
                <div className="row w-100 m-0 pt-10">
                    <Select
                        id={'Select_Urls'}
                        classNameContainer={"col-4 align-self-center pl-0"}
                        options={urls.map( (req_uri, i) => ({value: i.toString(), name: req_uri}))}
                        selectValue={urlForGetResult && urls[urlForGetResult] ? {value: urlForGetResult, name: urls[urlForGetResult]} : {}}
                        nameValue={'name'}
                        keyName={'value'}
                        handleChange={ (el, id) => {
                            setUrlForGetResult(el.value)
                        }}
                        errorMessage={''}
                        validations={{}}
                        loaded={false}
                    />
                    <div className="col-4 align-self-center">
                        <Button onClick={loadData} className={'hmc mr-1 w-100'}>Показати</Button>
                    </div>
                    <CheckBox
                        id={'all-check'}
                        className={'col-4'}
                        styles={{fontSize: '20px'}}
                        handle={ _ => setOnlyInstalled(!onlyInstalled)}
                        value={onlyInstalled}
                        label={"Лише встановлені"}
                    />
                </div>
            </div>
            <div className='height-list-filter'>
                <div className='list-of-poll mb-2 d-flex flex-column h-100'>
                    <RequestStatistic
                        uniqueRowId={'uid'}
                        emptyListText={'Пусто'}
                        disableSelect
                        items={items}
                        changeLimit={changeLimit}
                        listHead={listHead}
                        listItem={listItem}
                    />
                </div>
            </div>
            <div className={'row mt-3'}>
                <div className={'col-6'}>
                    <Button onClick={saveReqLimits} className={'w-100'} variant={'primary'}>Зберегти</Button>
                </div>
            </div>
        </>
    );
};


export default StatisticLimits;
