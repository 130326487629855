import React, { useEffect, useMemo, useState } from 'react';
import PropTypes                               from "prop-types";
import { useTranslation }                      from "react-i18next";
import InputMask                               from 'react-input-mask'

import { DEFAULT_ERROR_OBJECT }                from "../../constants";
import CloseInputButton                        from "../shared/CloseInputButton";
import InputSelectErrorBlock                   from "../shared/InputSelectErrorBlock";
import LoadInput                               from "../shared/LoadInput";
import { modifyPhoneNumber }                   from "../../utils/misc";

const maskRegex = /[0-9#]/

const InputPhone = ({id, placeholder, classes, errorMessage, required, value, closeButton, handleChange, handleBlur, countryISO, customMask}) => {

    const {t} = useTranslation();
    // const defMask = parseMask('+86 **********')

    const [load, setLoad] = useState(false)
    const [maskList, setMaskList] = useState([])

    // const maskList = {
    //     AT: '+43**********',
    //     BG: '+389*********',
    //     BE: '+32**********',
    //     HR: '+385*********',
    //     CY: '+357*********',
    //     CZ: '+420*********',
    //     EE: '+372*********',
    //     FR: '+33**********',
    //     DE: '+49**********',
    //     GR: '+30**********',
    //     HU: '+36**********',
    //     IT: '+39**********',
    //     LV: '+371*********',
    //     LT: '+370*********',
    //     LU: '+352*********',
    //     MX: '+52**********',
    //     MD: '+373*********',
    //     NL: '+31**********',
    //     PL: '+48**********',
    //     PT: '+351*********',
    //     RO: '+40**********',
    //     SK: '+421*********',
    //     SI: '+386*********',
    //     ES: '+34**********',
    //     UA: '+380*********',
    //     UZ: '+998*********',
    //     BY: '+375*********',
    //     IL: '+972*********',
    //     KZ: '+7***********',
    //     MA: '+212*********',
    //     MC: '+377*********',
    // }

    // const maskParseList = maskList.map(v => parseMask(v.mask));
    // for (let key in maskList) {
    //     maskParseList[key] = parseMask(maskList[key].mask)
    // }

    function parseMask(mask: string) {
        return mask.split('').map(v => {
            if (v === '*')
                return maskRegex;
            return v;
        });
    }

    const defMask = useMemo(() => {
        return maskList && Object.values(maskList).length ? Object.values(maskList).filter(m => m.default)[0].mask : ''
    }, [maskList])

    const [error, setError] = useState({...DEFAULT_ERROR_OBJECT})
    const [phone, setPhone] = useState(value ? value.toString() : '')

    const ref = React.createRef();

    useEffect(() => {
        ref.current.id = id;
    }, [])

    useEffect(() => {
        setPhone(modifyPhoneNumber(value ? value.toString() : ''))
    }, [value])

    function onChange(event) {
        const v = modifyPhoneNumber(event.currentTarget.value);
        if (typeof handleChange === 'function') {
            handleChange(v, id, ref, 'change');
        }
        if (value === undefined) {
            setPhone(v)
        }
    }

    function clear() {
        onChange({currentTarget: {value: ''}})
    }

    function onBlur(event) {
        if (typeof handleBlur === 'function') {
            handleBlur(event.currentTarget.value, id)
        }
    }

    return (
        <div className={'position-relative'}>
            <InputMask
                className={`form-placeholder form-control form-input
            ${classes ?? ''} ${error.isError || errorMessage && errorMessage.length || required && !phone.length ? 'is-invalid' : ''}`}
                ref={ref}
                mask={customMask !== undefined
                    ? (customMask === '' ? customMask : parseMask(customMask))
                    : (countryISO
                        ? (maskList.find(mask => mask.ISO === countryISO)
                            ? parseMask(maskList.find(mask => mask.ISO === countryISO).mask)
                            : '**************')
                        : defMask)}
                value={phone}
                onChange={onChange}
                onBlur={onBlur}
                maskPlaceholder={''}
                // alwaysShowMask
                placeholder={placeholder ?? t('phone')}
                disabled={load}
            />
            <CloseInputButton clear={clear} closeButton={closeButton && phone.length}/>
            <InputSelectErrorBlock
                show={error.isError || !!(errorMessage && errorMessage.length)}
                errorMessage={errorMessage}
            />
            <LoadInput loaded={load}/>
        </div>
    );
};

InputPhone.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    classes: PropTypes.string,
    errorMessage: PropTypes.string,
    required: PropTypes.bool
}

export default InputPhone;
